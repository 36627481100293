import { z } from "zod";
import * as ConfigForm from "../components/ConfigForm";
import * as BrandingForm from "../components/BrandingForm";
import * as SecurityForm from "../components/SecurityForm";
import { DESCRIPTION_CONTENT_LIMIT } from "../components/AnnouncementForm";
import * as EmailFooterForm from "../components/EmailFooterForm";

// prettier-ignore
const OrgConfigFormikSchema = ConfigForm.FormikSchema
  .merge(BrandingForm.FormikSchema)
  .merge(SecurityForm.FormikSchema)

export const FormikSchema = z
  .object({
    name: z.string().min(1, "This field is required"),
    path: z.string().min(1, "This field is required"),
    timezoneName: z.string().min(1, "This field is required"),
    organizationConfigs: OrgConfigFormikSchema,
    noStudentAnnouncement: z.object({
      id: z.string(),
      title: z.string(),
      description: z
        .string()
        .max(
          DESCRIPTION_CONTENT_LIMIT,
          `Description must be ${DESCRIPTION_CONTENT_LIMIT} characters or less.`
        ),
      active: z.boolean(),
      showType: z.string(),
      linkActive: z.boolean(),
      linkText: z.string().optional(),
      linkUrl: z.string().optional(),
    }),
  })
  .merge(EmailFooterForm.FormikSchema);

export type OrganizationFormSchema = z.infer<typeof FormikSchema>;
export type OrgConfigFormSchema = z.infer<typeof OrgConfigFormikSchema>;
