import { useUserProfile } from "src/hooks/useUserProfile";
import * as amplitude from "@amplitude/analytics-browser";
import { useEffect } from "react";
import useUser from "src/hooks/useUser";
import { useTeam } from "src/hooks/useTeam";
import { HasuraRole } from "src/types/hasuraRole";

export function AmplitudeUserProfileComponent({
  children,
}: {
  children: React.ReactNode;
}) {
  const userProfile = useUserProfile();
  const user = useUser();
  const team = useTeam();

  useEffect(() => {
    if (user.status !== "ok") return;
    const userId = user.data.id;

    const identify = new amplitude.Identify();

    // Set organization IDs as an array
    identify.set("organization_ids", user.data.organizations);

    // Set user type based on role
    const userType = user.data.role === HasuraRole.USER ? "Parent" : "Admin";
    identify.set("user_type", userType);
    identify.set("user_role", user.data.role);

    // If user has profile data, set person IDs
    if (userProfile.hasData()) {
      // Create org_person IDs in format {ORGID}_{PERSONID}
      const orgPersonIds = user.data.organizations.map(
        (orgId) => `${orgId}_${userProfile.data.id}`
      );
      identify.set("person_ids", orgPersonIds);
    }

    // Set team ID for admin users
    if (userType === "Admin" && team.hasData() && team.data !== null) {
      identify.set("person_team_id", team.data);
    }

    // Identify user with all properties
    amplitude.identify(identify, {
      user_id: userId,
    });
  }, [userProfile, user, team]);

  return <>{children}</>;
}
