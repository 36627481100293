import { Flex, FormControl } from "@chakra-ui/react";
import { AccountLookupConfigForm } from "./AccountLookupConfigForm";
import { LoginConfigForm } from "./LoginConfigForm";
import { InputControl } from "formik-chakra-ui";
import { z } from "zod";
import { AllOrgConfigs, DisabledConfigSchema } from "../types";
import * as OrgConfig from "@avela/organization-config-sdk";
import {
  createDisabledInitialConfig,
  getInitialValue,
} from "../services/orgConfig";

export const SecurityForm = () => {
  return (
    <Flex direction="column" gap={8}>
      <FormControl>
        <InputControl
          width="30rem"
          name="organizationConfigs.Auth0.clientId"
          label="Auth0 client ID"
          isRequired
        />
      </FormControl>
      <AccountLookupConfigForm />
      <LoginConfigForm />
    </Flex>
  );
};

/**
 * Formik schema and helpers functions
 */

export const FormikSchema = z.object({
  Auth0: z.union([
    OrgConfig.Auth0.ConfigSchema.extend({
      clientId: z.string().min(1, "This field is required"),
    }),
    DisabledConfigSchema,
  ]),
  Login: z.union([OrgConfig.Login.ConfigSchema, DisabledConfigSchema]),
  AccountLookup: z.union([
    OrgConfig.AccountLookup.ConfigSchema,
    DisabledConfigSchema,
  ]),
});
export type FormikForm = z.infer<typeof FormikSchema>;

export function getInitialValues(
  configs: AllOrgConfigs | undefined
): FormikForm {
  if (!configs) {
    // No configs, this is a new org. Disable all configs.
    return {
      Login: createDisabledInitialConfig("Login"),
      AccountLookup: createDisabledInitialConfig("AccountLookup", {
        supportUrl: "",
      }),
      Auth0: { disabled: false, clientId: "" },
    };
  }

  return {
    Login: getInitialValue("Login", configs),
    AccountLookup: getInitialValue("AccountLookup", configs, {
      supportUrl: "",
    }),
    Auth0: getInitialValue("Auth0", configs, { clientId: "" }),
  };
}
