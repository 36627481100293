import { Flex, FormControl, FormLabel, Heading, Text } from "@chakra-ui/react";
import { TextareaWithValidationControl } from "src/components/Inputs/TextareaWithValidationControl";
import { z } from "zod";
import { AllOrgConfigs } from "../types";
import { DefaultBranding } from "src/schemas/Branding";

export function EmailFooterForm() {
  return (
    <>
      <FormControl>
        <Flex direction="column">
          <FormLabel htmlFor="emailFooter">
            <Heading as="h3" variant="admin">
              Email footer
            </Heading>
            <Text color="blackAlpha.700" fontSize="sm">
              Every email sent from Avela will contain this footer
            </Text>
          </FormLabel>
          <TextareaWithValidationControl
            textareaProps={{ placeholder: "Enter email footer", mb: 2 }}
            name="emailFooter"
            isRequired
            onChange={console.log}
          />
        </Flex>
      </FormControl>
    </>
  );
}

export const FormikSchema = z.object({
  emailFooter: z.string(),
});

export type FormikType = z.infer<typeof FormikSchema>;
export const getInitialValues = (
  configs: AllOrgConfigs | undefined
): FormikType => {
  const branding = configs?.Branding;
  if (branding === undefined || branding.disabled) {
    return {
      emailFooter: DefaultBranding.message.footer,
    };
  }

  return {
    emailFooter: branding.message.footer,
  };
};
