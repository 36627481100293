import { matchPath, useLocation } from "react-router-dom";
import { ORGANIZATION } from "../services/url/constants";

export function useOrganizationPath(): string | undefined {
  const location = useLocation();
  const {
    params: { organization },
  } = matchPath(
    { path: `/${ORGANIZATION}`, end: false, caseSensitive: false },
    location.pathname
  ) ?? {
    params: {},
  };

  return organization;
}
