import { QueryClient } from "@tanstack/react-query";
import { client } from "src/generated/hooks/form-service/requests/services.gen";

export function createQueryClient(accessToken: string, baseUrl: string) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 minutes
        retry: 1,
      },
    },
  });

  // Configure the generated REST client with auth headers
  client.setConfig({
    baseUrl,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  return queryClient;
}
