import { QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import useAccessToken from "src/hooks/useAccessToken";
import useUser from "src/hooks/useUser";
import { createQueryClient } from "src/services/createQueryClient";
import { Status } from "src/types/authData";
import { Loading } from "../Feedback/Loading";
import * as Env from "src/services/env";

type Props = { children: React.ReactElement };

export const QueryClientProviderWithCredential: React.FC<Props> = ({
  children,
}) => {
  const user = useUser();
  const accessToken = useAccessToken();
  const env = Env.read();

  if (accessToken.status === Status.LOADING || user.status === Status.LOADING) {
    return <Loading />;
  }

  if (
    accessToken.status === Status.UNAUTHENTICATED ||
    user.status === Status.UNAUTHENTICATED
  ) {
    // This should not be possible, but the type system doesn't know since we wrap the real handler for this inside useEffect
    return null;
  }

  const formServiceQueryClient = createQueryClient(
    accessToken.data,
    env.REACT_APP_FORM_SERVICE_URL
  );

  return (
    <QueryClientProvider client={formServiceQueryClient}>
      {children}
    </QueryClientProvider>
  );
};
