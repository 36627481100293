import { Grid, GridItem, HStack, Stack, Text } from "@chakra-ui/react";
import Mustache from "mustache";
import { FunctionComponent, useMemo } from "react";
import * as EmailBody from "../clientBranding/emailBody";
import { RenderPreviewEmail } from "./RenderPreviewEmail";
import { usePartials } from "./partials";
import { FormType } from "./types";
import { useBranding } from "src/components/Providers/BrandingProvider";

type Props = { formValue: FormType };

export const PreviewMessageTemplate: FunctionComponent<Props> = (props) => {
  const { formValue } = props;
  const partials = usePartials();

  const branding = useBranding();

  const { emailSubject, emailMarkup, emailText, smsBody } = useMemo(() => {
    if (!branding || !partials) return {};

    const emailSubject = Mustache.render(formValue.emailSubject, partials);
    const emailMarkup = EmailBody.template(
      branding,
      Mustache.render(formValue.emailMarkup, partials)
    );
    // Mustache.render(formValue.emailMarkup, partials); // TODO: sanitize HTML
    const emailText = Mustache.render(formValue.emailText, partials);
    const smsBody = Mustache.render(formValue.smsBody, partials);
    return {
      emailSubject,
      emailMarkup,
      emailText,
      smsBody
    };
  }, [
    branding,
    formValue.emailMarkup,
    formValue.emailSubject,
    formValue.emailText,
    formValue.smsBody,
    partials
  ]);

  return (
    <Stack gap="5" flexGrow="1">
      <Stack>
        <Text fontSize="sm">Subject line</Text>
        <Text>{emailSubject}</Text>
      </Stack>
      <Grid
        templateColumns="repeat(auto-fit, minmax(30rem, 1fr))"
        gap="5"
        templateRows="1fr"
        flexGrow="1"
      >
        <GridItem display="flex" flexGrow="1" flexDirection="column">
          <Stack minHeight="30rem" flexGrow="1">
            <Text fontSize="sm">HTML</Text>
            <RenderPreviewEmail html={emailMarkup ?? ""} flexGrow="1" />
          </Stack>
        </GridItem>
        <GridItem
          display="flex"
          flexDirection="column"
          height="100%"
          flexWrap="nowrap"
        >
          <Stack height="100%">
            <Text fontSize="sm">Plain text</Text>
            <Text as="pre" overflow="auto">
              {emailText}
            </Text>
          </Stack>
        </GridItem>
      </Grid>
      <Stack>
        <Text fontSize="sm">SMS message</Text>
        <Text>{smsBody}</Text>
      </Stack>

      <HStack justifyContent="right"></HStack>
    </Stack>
  );
};
