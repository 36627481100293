import { Button } from "@chakra-ui/button";
import { Flex, Heading } from "@chakra-ui/layout";
import React from "react";
import { NavLink } from "react-router-dom";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { GET_ORGANIZATIONS_FOR_LISTVIEW } from "./graphql/queries";
import { List } from "./List";

export const Index = (): React.ReactElement | null => {
  const { remoteData, refetch } =
    useRemoteDataQuery<GQL.GetOrganizationsForListView>(
      GET_ORGANIZATIONS_FOR_LISTVIEW,
      { fetchPolicy: "network-only" }
    );

  const handleChange = React.useCallback(() => {
    refetch();
  }, [refetch]);

  return (
    <Flex direction="column" alignItems="flex-start" rowGap={6}>
      <Heading>Organization</Heading>
      <Button as={NavLink} to={Url.Admin.Organizations.new()} marginY={6}>
        Create new organization
      </Button>

      <GQLRemoteDataView remoteData={remoteData}>
        {(data) => (
          <List organizations={data.organization} onChange={handleChange} />
        )}
      </GQLRemoteDataView>
    </Flex>
  );
};

export { Edit } from "./Edit";
export { New } from "./New";
