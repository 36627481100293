import { Skeleton } from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { useParams } from "react-router-dom";
import { GenericError } from "src/components/Feedback/GenericError";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_MESSAGE_TEMPLATE } from "../graphql/queries";
import {
  defaultTemplateRankDisabled,
  defaultTemplateRankEnabled
} from "../messageTemplateBody";
import { FormType } from "./components/types";
import { EditFormTemplateEditMessagesProvider } from "./context";
import { EditMessageTemplateForm } from "./EditMessageTemplateForm";
import useRequiredHasuraRoles from "src/hooks/useRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { useFlags } from "src/components/Providers/FeatureFlagProvider";

export const EditMessageTemplate: FunctionComponent = () => {
  const { formTemplateId = "", messageTemplateType = "" } = useParams();
  const isAvelaAdmin = useRequiredHasuraRoles([HasuraRole.ADMIN]);
  const flags = useFlags(["triggered-comms-org-admins"]);

  const isTriggeredCommsOrgAdminsEnabled =
    flags["triggered-comms-org-admins"].enabled;

  const templateType = messageTemplateType as GQL.message_template_type_enum;
  const { remoteData } = useRemoteDataQuery<
    GQL.GetMessageTemplate,
    GQL.GetMessageTemplateVariables
  >(GET_MESSAGE_TEMPLATE, {
    variables: {
      formTemplateId: formTemplateId,
      templateType
    },
    skip: !formTemplateId || !messageTemplateType
  });

  if (!formTemplateId || !messageTemplateType) {
    return <GenericError />;
  }

  if (!isAvelaAdmin && !isTriggeredCommsOrgAdminsEnabled) {
    return <GenericError />;
  }

  return (
    <GQLRemoteDataView
      remoteData={remoteData}
      error={() => <GenericError />}
      loading={<Skeleton width="16rem" height="2.25rem" />}
    >
      {(data) => {
        const template = data.message_template[0];
        const rankingEnabled =
          data.form_template_by_pk?.sections[0]?.schools_ranking_section
            ?.ranking_enabled ?? false;
        const defaultTemplate = rankingEnabled
          ? defaultTemplateRankEnabled
          : defaultTemplateRankDisabled;

        const initialValues: FormType = template
          ? {
              emailSubject: template.email_subject,
              emailMarkup: template.email_markup,
              emailText: template.email_plain_text,
              smsBody: template.sms_body
            }
          : {
              emailSubject: defaultTemplate[templateType].emailSubject,
              emailMarkup: defaultTemplate[templateType].emailHtml,
              emailText: defaultTemplate[templateType].emailText,
              smsBody: defaultTemplate[templateType].sms
            };

        return (
          <EditFormTemplateEditMessagesProvider
            editMessagesInitialValues={initialValues}
            messageTemplateType={templateType}
            formTemplateId={formTemplateId}
          >
            <EditMessageTemplateForm
              isLoading={remoteData.isLoading()}
              messageEnabled={template?.enabled}
            />
          </EditFormTemplateEditMessagesProvider>
        );
      }}
    </GQLRemoteDataView>
  );
};
