import { gql } from "@apollo/client";

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationFragment on organization {
    id
    name
    path
    timezone_name
    announcements {
      id
      title
      description
      active
      condition
      display_type
      link_text
      link_url
    }
  }
`;

export const GET_ORGANIZATION_BY_ID = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganization($id: uuid!) {
    organization_by_pk(id: $id) {
      ...OrganizationFragment
    }
  }
`;

export const GET_ORGANIZATIONS = gql`
  ${ORGANIZATION_FRAGMENT}
  query GetOrganizations {
    organization {
      ...OrganizationFragment
    }
  }
`;

export const GET_ORGANIZATIONS_FOR_LISTVIEW = gql`
  query GetOrganizationsForListView {
    organization(order_by: { name: asc }) {
      id
      name
      path
      status
    }
  }
`;
