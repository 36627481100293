import React from "react";
import { Loading } from "src/components/Feedback/Loading";
import { BrandedChakraProvider } from "./BrandedChakraProvider";
import { Branding, DefaultBranding } from "src/schemas/Branding";
import * as RD from "src/types/remoteData";
import { useOrgConfig } from "src/hooks/useOrgConfig";

export type BrandingContextType = RD.RemoteData<Error, Branding>;

const BrandingContext = React.createContext<BrandingContextType>(RD.notAsked());

export function BrandingProvider({ children }: React.PropsWithChildren<{}>) {
  const branding = useOrgConfig("Branding");

  return (
    <BrandingContext.Provider value={branding}>
      {branding.isLoading() ? (
        <BrandedChakraProvider>
          <Loading />
        </BrandedChakraProvider>
      ) : (
        children
      )}
    </BrandingContext.Provider>
  );
}

export function useBranding(): Branding {
  const context = React.useContext(BrandingContext);
  if (context === undefined) {
    console.error("useBranding() must be used within a BrandingProvider");
  }

  return context.withDefault(DefaultBranding);
}
