import { Box, Heading, HStack, Spacer, VStack } from "@chakra-ui/layout";
import {
  Button,
  Flex,
  Tab,
  TabList,
  Tabs,
  Tag,
  TagLeftIcon,
  Text,
} from "@chakra-ui/react";
import _ from "lodash";
import React from "react";
import {
  RiClipboardLine,
  RiFilter3Line,
  RiLightbulbLine,
} from "react-icons/ri";
import { useSearchParams } from "react-router-dom";
import { NoDataTable } from "src/components/Feedback/NoDataTable";
import { SearchInput } from "src/components/Inputs/SearchInput";
import { GQLRemoteDataView } from "src/components/Layout/RemoteDataView";
import { Breadcrumb } from "src/components/Navigation/Breadcrumb";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useFormTemplates } from "src/components/Providers/FormTemplateProvider";
import {
  AttendanceTypes,
  FormTabsTypes,
  SearchAndFilterTypes,
} from "src/constants";
import { EnrollmentPeriodIdProvider } from "src/hooks/useGlossary";
import { useOrganization } from "src/hooks/useOrganization";
import { useSchoolAdmin } from "src/hooks/useSchoolAdmin";
import { useFormsList } from "src/scenes/orgAdmin/forms/components/useFormsList";
import * as breadcrumb from "src/services/breadcrumb";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import * as RD from "src/types/remoteData";
import { CreateFormsButton } from "./components/CreateFormsButton";
import { ImportMenuInput } from "./components/ImportMenuInput";
import { NoForms } from "./components/NoForms";
import { TableHeader } from "./components/TableHeader";
import { FormSearchContext } from "./context";
import { DropoffFormsList } from "./DropoffFormsList";
import { FormFilters } from "./formFilters/FormFilters";
import { FormsList } from "./FormsList";

export const Forms = (): React.ReactElement | null => {
  const { isElevatedSchoolAdmin, hasApplyingSchools, hasAttendingSchools } =
    useSchoolAdmin();
  const [searchParams] = useSearchParams();
  const organization = useOrganization();
  const {
    filter,
    search,
    enrollmentPeriodId,
    formsList,
    tabs,
    remoteData,
    dropoffForms,
    pagination,
    setPagination,
    selection,
    setSelection,
  } = useFormsList();
  const { selectedNavFormTemplate } = useFormTemplates();

  const { setAttendance } = formsList;
  React.useEffect(() => {
    if (!isElevatedSchoolAdmin) {
      if (searchParams.has(SearchAndFilterTypes.Attendance)) {
        setAttendance("");
      }
      return;
    }

    // There is some messy logic here because permissions are all temporarily
    // granted while initially evaluting them, which means that we cannot rely
    // on their initial values to be correct.  So we can assign an initial value
    // to the attendance filter if unset, but we also have to correct it if
    // those permissions change.
    const attendance = searchParams.get(SearchAndFilterTypes.Attendance) ?? "";
    const allowedValues: string[] = [
      ...(hasAttendingSchools ? [AttendanceTypes.Attending] : []),
      ...(hasApplyingSchools ? [AttendanceTypes.Applying] : []),
    ];

    if (!allowedValues.includes(attendance)) {
      setAttendance(allowedValues[0] ?? AttendanceTypes.Applying);
    }
  }, [
    isElevatedSchoolAdmin,
    hasApplyingSchools,
    hasAttendingSchools,
    searchParams,
    setAttendance,
  ]);

  let tip = <></>;
  if (tabs.current.type === FormTabsTypes.DropoffForms) {
    const previousFormTemplateId = tabs.current.formTemplateId;
    const from = tabs.visibleTabs.find(
      (tab) => tab.key === previousFormTemplateId
    )?.label;
    const to = selectedNavFormTemplate?.name;

    if (from && to) {
      tip = (
        <Flex gap={4} alignItems="flex-start" marginTop={6}>
          <Tag
            width="4rem"
            color="white"
            backgroundColor="purple.500"
            alignItems="center"
            justifyContent="center"
          >
            <TagLeftIcon boxSize="0.75rem" as={RiLightbulbLine} />
            <Text>Tip</Text>
          </Tag>
          <Text>
            Students from {from} in this list have not yet started {to}, so
            there are fewer things you can do with these records in this view.
          </Text>
        </Flex>
      );
    }
  }

  let formTemplateRemoteData: RD.RemoteData<Error, GQL.FormTemplateFragment>;
  let formsContent;

  const tagGroups = remoteData.hasData() ? remoteData.data.tagGroups : null;
  switch (tabs.current.type) {
    case FormTabsTypes.DropoffForms:
      formTemplateRemoteData = dropoffForms.previousFormTemplateRemoteData;
      formsContent = (
        <DropoffFormsList
          enrollmentPeriodId={enrollmentPeriodId}
          limit={pagination.limit}
          offset={pagination.offset}
          previousFormTemplateRemoteData={
            dropoffForms.previousFormTemplateRemoteData
          }
          count={dropoffForms.count}
          remoteData={dropoffForms.remoteData}
          onFetchMore={setPagination}
          selection={selection}
          onSelectionChange={setSelection}
          onFetchAll={dropoffForms.fetchAll}
          onFetchAllIds={dropoffForms.fetchAllIds}
          onRefetch={dropoffForms.refetch}
          onFetchByIds={dropoffForms.fetchByIds}
          tagGroups={tagGroups?.tag_group ?? []}
        />
      );
      break;

    default:
      formTemplateRemoteData = remoteData
        .mapError<Error>(_.identity)
        .andThen((data) =>
          data.formTemplate
            ? RD.success(data.formTemplate)
            : RD.failure(new Error("Invalid form"))
        );
      formsContent = (
        <GQLRemoteDataView remoteData={remoteData}>
          {({ tableData, formTemplate, count, tagGroups }) => {
            if (!tableData?.length || !formTemplate) {
              if (filter.isApplied || search.isApplied) {
                /*
                 * When filters are applied, forms might still exist.
                 * Show the "no matching forms" view.
                 */
                return (
                  <>
                    {isElevatedSchoolAdmin && (
                      <TableHeader
                        count={0}
                        setAttendance={formsList.setAttendance}
                      />
                    )}
                    <NoDataTable
                      buttonText="Clear"
                      icon={RiClipboardLine}
                      onButtonAction={formsList.clearAll}
                      text="No forms found"
                    />
                  </>
                );
              } else {
                return selectedNavFormTemplate ? (
                  <NoForms
                    enrollmentPeriodId={enrollmentPeriodId}
                    formTemplateId={selectedNavFormTemplate.id}
                    formTemplateName={selectedNavFormTemplate.name}
                  />
                ) : null;
              }
            } else {
              return (
                <FormsList
                  enrollmentPeriodId={enrollmentPeriodId}
                  forms={tableData}
                  formTemplate={formTemplate}
                  limit={pagination.limit}
                  offset={pagination.offset}
                  count={count}
                  selection={selection}
                  onSelectionChange={setSelection}
                  onRefetch={formsList.refetch}
                  onFetchAll={formsList.fetchAll}
                  onFetchAllIds={formsList.fetchAllIds}
                  onFetchByIds={formsList.fetchDetailed}
                  onFetchMore={setPagination}
                  onSort={formsList.sort}
                  isLoading={remoteData.isLoading()}
                  onSelectAll={formsList.selectAll}
                  tabType={tabs.current.type}
                  tagGroups={tagGroups.tag_group}
                  setAttendance={formsList.setAttendance}
                />
              );
            }
          }}
        </GQLRemoteDataView>
      );
      break;
  }

  return (
    <FormSearchContext.Provider value={search.expression}>
      <EnrollmentPeriodIdProvider enrollmentPeriodId={enrollmentPeriodId}>
        <VStack align="stretch" minBlockSize="100%">
          <Breadcrumb
            items={breadcrumb.form.getBreadcrumbsForList(
              organization,
              selectedNavFormTemplate,
              search.params.toString()
            )}
            mb={4}
          />

          <HStack pb={8}>
            <Heading as="h1" size="lg">
              {selectedNavFormTemplate?.name ?? "Forms"}
            </Heading>

            <Spacer />

            <Box>
              <WithUserPermissions permissions={["form:update"]}>
                <ImportMenuInput
                  onFormListImported={formsList.imported}
                  formTemplate={selectedNavFormTemplate}
                />
              </WithUserPermissions>
            </Box>

            <WithRequiredHasuraRoles
              roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
            >
              <WithUserPermissions permissions={["form:create"]}>
                {selectedNavFormTemplate && (
                  <CreateFormsButton
                    enrollmentPeriodId={enrollmentPeriodId}
                    formTemplateId={selectedNavFormTemplate.id}
                    formTemplateName={selectedNavFormTemplate.name}
                  />
                )}
              </WithUserPermissions>
            </WithRequiredHasuraRoles>
          </HStack>

          <Tabs index={tabs.currentIndex}>
            <TabList>
              {tabs.visibleTabs.map((tab) => (
                <Tab
                  key={tab.key}
                  value={tab.key}
                  onClick={() => tabs.onChange(tab)}
                  fontStyle={
                    tab.type === FormTabsTypes.DropoffForms
                      ? "italic"
                      : undefined
                  }
                >
                  {tab.type === FormTabsTypes.DropoffForms
                    ? `From: ${tab.label}`
                    : tab.label}
                </Tab>
              ))}
            </TabList>
          </Tabs>

          {tip}

          <HStack py={6}>
            <SearchInput {...search} />
            <Button
              variant="outline"
              leftIcon={<RiFilter3Line />}
              onClick={filter.toggle}
            >
              Filter
            </Button>
          </HStack>

          <HStack alignItems="start" flexGrow={1}>
            <Box alignSelf="stretch" width={filter.isOpen ? "70%" : "100%"}>
              {formsContent}
            </Box>

            {filter.isOpen && (
              <FormFilters
                organizationId={organization
                  .map((org) => org.id)
                  .withDefault("")}
                formTemplateRemoteData={formTemplateRemoteData}
                onToggleFilter={filter.toggle}
                onClearFilters={filter.clear}
                enrollmentPeriodId={enrollmentPeriodId}
              />
            )}
          </HStack>
        </VStack>
      </EnrollmentPeriodIdProvider>
    </FormSearchContext.Provider>
  );
};
