import {
  Button,
  Flex,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Image,
  PopoverContentProps,
  FormErrorMessage,
  FormControl,
} from "@chakra-ui/react";
import { FunctionComponent } from "react";
import { RiIndeterminateCircleLine } from "react-icons/ri";

type Props = {
  name: string;
  error?: string;
  previewUrl?: string;
  onDelete: () => void;
  previewPopoverProps?: PopoverContentProps;
};
export const FileItem: FunctionComponent<Props> = ({
  name,
  error,
  previewUrl,
  onDelete,
  previewPopoverProps,
}) => {
  return (
    <FormControl as={Flex} direction="column" isInvalid={error !== undefined}>
      <Flex>
        <Popover>
          <PopoverTrigger>
            <Button
              variant="link"
              size="xs"
              minWidth="15rem"
              textAlign="left"
              fontSize="md"
              fontWeight="normal"
              whiteSpace="normal"
              overflow="hidden"
              colorScheme={error ? "red" : undefined}
            >
              {name}
            </Button>
          </PopoverTrigger>
          {previewUrl && (
            <PopoverContent width="fit-content" {...previewPopoverProps}>
              <PopoverBody>
                <Image src={previewUrl} alt={name} maxHeight="300px" />
              </PopoverBody>
            </PopoverContent>
          )}
        </Popover>
        <IconButton
          icon={<RiIndeterminateCircleLine />}
          color="red"
          aria-label="Delete file"
          variant="ghost"
          padding="0"
          size="xs"
          onClick={onDelete}
        />
      </Flex>
      {error && <FormErrorMessage>{error}</FormErrorMessage>}
    </FormControl>
  );
};
