import { FunctionComponent } from "react";
import { FileItem } from "./FileItem";

const InvalidFileFormatErrorMesage = "Unsupported file format";

type Props = {
  name: string;
  onClose: () => void;
};
export const InvalidLogo: FunctionComponent<Props> = ({ name, onClose }) => {
  return (
    <FileItem
      name={name}
      error={InvalidFileFormatErrorMesage}
      onDelete={onClose}
    />
  );
};
