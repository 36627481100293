import type { ReactElement } from "react";
import type { RemoteData } from "src/types/remoteData";
import { GenericErrorBoundary } from "../../Feedback/GenericErrorBoundary";

type RemoteDataContentProps<E, T> = {
  children: (data: T) => ReactElement[] | ReactElement | null;
  hideWhileReloading?: boolean;
  remoteData: RemoteData<E, T>;
};

/**
 * Use the given `children` render function to display the data stored in the
 * given `remoteData`. When `hideWhileReloading` is `true` and the given
 * `remoteData` is loading, nothing will be rendered.
 */
export function RemoteDataContent<E, T>(props: RemoteDataContentProps<E, T>) {
  const { children, hideWhileReloading = false, remoteData } = props;

  if ((hideWhileReloading && remoteData.isLoading()) || !remoteData.hasData()) {
    return null;
  }

  return (
    <GenericErrorBoundary>{children(remoteData.data)}</GenericErrorBoundary>
  );
}
