import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React from "react";
import { useOrganizationPath } from "src/hooks/useOrganizationPath";
import * as Env from "src/services/env";
import { SharedState } from "src/services/sharedState";
import { AUTH } from "src/services/url/constants";
import { saveAuthContext } from "./state/localStorage";
import { EXPLORE_SCHOOLS_TOKEN } from "src/types/searchParams";
import { saveContext } from "src/scenes/state/localStorage";
import { useSearchParams } from "react-router-dom";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { GenericError } from "../Feedback/GenericError";
import { Loading } from "../Feedback/Loading";
import { useAuth0ClientId } from "src/hooks/useAuth0ClientId";

type Props = { children: React.ReactNode };

export function Auth0ProviderWithHistory({ children }: Props) {
  const env = Env.read();

  const [searchParams] = useSearchParams();
  const exploreSavedSchoolRefIDs = decodeURIComponent(
    searchParams.get(EXPLORE_SCHOOLS_TOKEN) ?? ""
  )
    .split(",")
    .filter((x) => x);

  if (exploreSavedSchoolRefIDs.length)
    saveContext({ exploreSavedSchoolRefIDs: exploreSavedSchoolRefIDs });

  const onRedirectCallback = React.useCallback((appState?: AppState) => {
    SharedState.redirectURL(appState?.returnTo || window.location.pathname);
  }, []);

  const organization = useOrganizationPath() ?? "";
  const clientIdRD = useAuth0ClientId();

  React.useEffect(() => {
    if (clientIdRD.hasData()) {
      saveAuthContext({
        organization,
        clientId: clientIdRD.data.clientId,
      });
    }
  }, [clientIdRD, organization]);

  return (
    <RemoteDataView
      remoteData={clientIdRD}
      loading={<Loading />}
      error={(error) => (
        <GenericError
          message="Missing clientId, most likely due to incomplete organization creation."
          error={error}
        />
      )}
    >
      {({ type, clientId }) => {
        return (
          <Auth0Provider
            domain={env.REACT_APP_AUTH0_DOMAIN}
            clientId={clientId}
            redirectUri={
              type === "organization"
                ? `${window.location.origin}/${organization}/${AUTH}`
                : `${window.location.origin}/${AUTH}`
            }
            audience={env.REACT_APP_HASURA_URL}
            onRedirectCallback={onRedirectCallback}
            useRefreshTokens={true} // uses "modern" OAuth technique for keeping a valid JWT
            // setting cacheLocation=localstorage results in fewer calls to OAuth making the apply app faster.
            cacheLocation="localstorage"
          >
            {children}
          </Auth0Provider>
        );
      }}
    </RemoteDataView>
  );
}
