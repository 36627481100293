import React, { useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Text,
} from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { UploadFileInput } from "src/components/Inputs/UploadFileInput";
import { ReactComponent as LaunchAppSvg } from "src/images/launch-app.svg";
import { ColorInput } from "src/components/Inputs/ColorInput";
import { z } from "zod";
import * as OrgConfig from "@avela/organization-config-sdk";
import { useFormikContext } from "formik";
import { OrganizationFormSchema } from "../types/formikSchema";
import * as Logo from "./Logo";
import _ from "lodash";
import { BrandingPreview } from "./BrandingPreview";
import { Env, useEnv } from "src/services/env";
import useAccessToken from "src/hooks/useAccessToken";
import { AuthData, Status } from "src/types/authData";
import * as ColorPalette from "./ColorPalette";
import { AllOrgConfigs } from "../types";
import { useConfirmationDialog } from "src/hooks/useConfirmationDialog";

export const BrandingForm = () => {
  const { confirm, confirmationDialog } = useConfirmationDialog({
    header: "Replace logo",
    body: "",
    confirmButton: { label: "Upload and replace" },
    cancelButton: { label: "Cancel" },
  });
  const formik = useFormikContext<OrganizationFormSchema>();
  const [invalidColorLogo, setInvalidColorLogo] = useState<
    string | undefined
  >();
  const [invalidWhiteLogo, setInvalidWhiteLogo] = useState<
    string | undefined
  >();

  const confirmOverwriteExistingLogo = async (logo: keyof Logos) => {
    const logoType =
      logo === "colorLogoPng" || logo === "colorLogoSvg"
        ? "Black or Fullcolor"
        : "White";
    const imageType =
      logo === "colorLogoPng" || logo === "whiteLogoPng" ? "PNG" : "SVG";
    return await confirm({
      body: `You’ve already uploaded a ${logoType} logo ${imageType} file. Would you like to replace it?`,
    });
  };

  const updateFormikFieldLogo = async (logoKey: keyof Logos, file: File) => {
    const logo = formik.values.organizationConfigs.Branding[logoKey];
    if (logo?.type === "existing") {
      if (!(await confirmOverwriteExistingLogo(logoKey))) {
        return;
      }
    }

    let imageType: "png" | "svg";
    switch (logoKey) {
      case "colorLogoPng":
      case "whiteLogoPng":
        imageType = "png";
        break;
      case "colorLogoSvg":
      case "whiteLogoSvg":
        imageType = "svg";
        break;
    }

    const objectUrl = URL.createObjectURL(file);
    formik.setFieldValue(`organizationConfigs.Branding.${logoKey}`, {
      type: "new",
      objectUrl,
      imageType,
      name: file.name,
    } satisfies Logo.NewLogo);
  };

  const handleLogoUpload = async (
    colorType: "color" | "white",
    files: FileList
  ): Promise<void> => {
    if (colorType === "color") {
      setInvalidColorLogo(undefined);
      Array.from(files).forEach(async (file) => {
        if (file.type === "image/png") {
          await updateFormikFieldLogo("colorLogoPng", file);
        } else if (file.type === "image/svg+xml") {
          await updateFormikFieldLogo("colorLogoSvg", file);
        } else {
          setInvalidColorLogo(file.name);
        }
      });
    } else if (colorType === "white") {
      setInvalidWhiteLogo(undefined);
      Array.from(files).forEach(async (file) => {
        if (file.type === "image/png") {
          await updateFormikFieldLogo("whiteLogoPng", file);
        } else if (file.type === "image/svg+xml") {
          await updateFormikFieldLogo("whiteLogoSvg", file);
        } else {
          setInvalidWhiteLogo(file.name);
        }
      });
    }
  };

  const previewBackgroundColor = _.isEmpty(
    formik.values.organizationConfigs.Branding.primaryColor
  )
    ? OrgConfig.Branding.DefaultConfig.colors.primary["500"]
    : formik.values.organizationConfigs.Branding.primaryColor;

  const [showPreview, setShowPreview] = useState(false);

  const deleteLogo = async (formikFieldName: keyof Logos) => {
    const logo = formik.values.organizationConfigs.Branding[formikFieldName];
    if (logo?.type === "existing") {
      let body: string;
      switch (formikFieldName) {
        case "colorLogoPng":
          body = `Are you sure you want to remove Black or Fullcolor PNG logo?`;
          break;
        case "colorLogoSvg":
          body = `Are you sure you want to remove Black or Fullcolor SVG logo?`;
          break;
        case "whiteLogoPng":
          body = `Are you sure you want to remove White PNG logo?`;
          break;
        case "whiteLogoSvg":
          body = `Are you sure you want to remove White SVG logo?`;
          break;
      }
      if (
        !(await confirm({
          body,
          header: "Remove logo",
          confirmButton: { label: "Remove logo" },
        }))
      ) {
        return;
      }
    }

    formik.setFieldValue(
      `organizationConfigs.Branding.${formikFieldName}`,
      getDefaultLogo(formikFieldName)
    );
  };

  return (
    <Flex gap={4}>
      <Flex direction="column" gap={2} flex="1">
        <Heading as="h3" variant="admin">
          Logos
        </Heading>
        <Flex direction="column" gap={2} alignItems="flex-start">
          <Text fontSize="md" fontWeight="500">
            Black or full color logo
          </Text>
          <Text color="blackAlpha.700" fontSize="sm">
            Upload a logo that can be used over light or white backgrounds
          </Text>
          {formik.values.organizationConfigs.Branding.colorLogoPng && (
            <Logo.LogoPreview
              logo={formik.values.organizationConfigs.Branding.colorLogoPng}
              onDelete={() => deleteLogo("colorLogoPng")}
            />
          )}
          {formik.values.organizationConfigs.Branding.colorLogoSvg && (
            <Logo.LogoPreview
              logo={formik.values.organizationConfigs.Branding.colorLogoSvg}
              onDelete={() => {
                deleteLogo("colorLogoSvg");
              }}
            />
          )}
          {invalidColorLogo && (
            <Logo.InvalidLogo
              name={invalidColorLogo}
              onClose={() => setInvalidColorLogo(undefined)}
            />
          )}
          <UploadFileInput
            boxProps={{ marginY: "0" }}
            buttonLabel="Upload files"
            accept="image/png, image/svg+xml"
            multiple={true}
            onUpload={(files) => handleLogoUpload("color", files)}
          />
          <Text color="blackAlpha.700" fontSize="xs">
            Upload a PNG and an SVG version of the logo, with no background
          </Text>
        </Flex>
        <Flex direction="column" gap={2} alignItems="flex-start">
          <Text fontSize="sm" fontWeight="500">
            Whitescale logo
          </Text>
          <Text color="blackAlpha.700" fontSize="sm">
            Upload a logo that can be used over dark or primary color
            backgrounds
          </Text>
          {formik.values.organizationConfigs.Branding.whiteLogoPng && (
            <Logo.LogoPreview
              logo={formik.values.organizationConfigs.Branding.whiteLogoPng}
              onDelete={() => deleteLogo("whiteLogoPng")}
              previewPopoverProps={{
                background: previewBackgroundColor,
              }}
            />
          )}
          {formik.values.organizationConfigs.Branding.whiteLogoSvg && (
            <Logo.LogoPreview
              logo={formik.values.organizationConfigs.Branding.whiteLogoSvg}
              onDelete={() => deleteLogo("whiteLogoSvg")}
              previewPopoverProps={{ background: previewBackgroundColor }}
            />
          )}
          {invalidWhiteLogo && (
            <Logo.InvalidLogo
              name={invalidWhiteLogo}
              onClose={() => setInvalidWhiteLogo(undefined)}
            />
          )}
          <UploadFileInput
            boxProps={{ marginY: "0" }}
            buttonLabel="Upload files"
            accept="image/png, image/svg+xml"
            multiple
            onUpload={(files) => handleLogoUpload("white", files)}
          />
          <Text color="blackAlpha.700" fontSize="xs">
            Upload a PNG and an SVG version of the logo, with no background
          </Text>
        </Flex>
        <Heading as="h3" variant="admin">
          Brand
        </Heading>
        <Flex direction="column" gap={2} alignItems="flex-start">
          <Text fontSize="sm" fontWeight="500">
            Primary color
          </Text>
          <Text color="blackAlpha.700" fontSize="sm">
            Enter the brand's primary color HEX code
          </Text>
          <FormControl
            direction="column"
            alignItems="flex-start"
            as={Flex}
            isInvalid={
              formik.errors.organizationConfigs?.Branding?.primaryColor !==
              undefined
            }
          >
            <ColorInput
              name="organizationConfigs.Branding.primaryColor"
              onChange={(value, field) => {
                formik.setFieldValue(
                  "organizationConfigs.Branding.disabled",
                  false
                );
                formik.setFieldValue(field.name, value);
              }}
            />
            <FormErrorMessage>
              {formik.errors.organizationConfigs?.Branding?.primaryColor}
            </FormErrorMessage>
          </FormControl>
          <ColorPalette.ColorPalette
            color={formik.values.organizationConfigs.Branding.primaryColor}
          />
        </Flex>
      </Flex>
      <Flex
        direction="column"
        border="1px solid"
        borderColor="gray.200"
        borderRadius="md"
        flex="1"
        alignItems="center"
        justifyContent="center"
        overflow="hidden"
        padding={showPreview ? 0 : 4}
      >
        {showPreview ? (
          <BrandingPreview
            primaryColor={
              formik.values.organizationConfigs.Branding.primaryColor
            }
            colorLogoSvg={
              formik.values.organizationConfigs.Branding.colorLogoSvg
            }
            whiteLogoSvg={
              formik.values.organizationConfigs.Branding.whiteLogoSvg
            }
          />
        ) : (
          <Flex direction="column" gap={4} alignItems="center">
            <EmptyState
              description="Add logos and a primary color to preview your Brand"
              Svg={LaunchAppSvg}
            />
            <Button
              onClick={() => setShowPreview(true)}
              variant="outline"
              colorScheme="gray"
            >
              Preview
            </Button>
          </Flex>
        )}
      </Flex>
      {confirmationDialog}
    </Flex>
  );
};

const LogosSchema = z.object({
  colorLogoPng: Logo.LogoSchema.optional(),
  colorLogoSvg: Logo.LogoSchema.optional(),
  whiteLogoPng: Logo.LogoSchema.optional(),
  whiteLogoSvg: Logo.LogoSchema.optional(),
});
export const BrandingSchema = z.discriminatedUnion("disabled", [
  z
    .object({
      disabled: z.literal(false), // This is needed to satisfy type system. We don't actually use it.
      primaryColor: z
        .string()
        .regex(/^(#([A-Fa-f0-9]{6}))?$/i, "Invalid hex color format")
        .or(z.literal(""))
        .optional(),
    })
    .merge(LogosSchema),
]);
export const FormikSchema = z.object({
  Branding: BrandingSchema,
});
type Logos = z.infer<typeof LogosSchema>;
export type FormikType = z.infer<typeof FormikSchema>;
export type Branding = z.infer<typeof BrandingSchema>;

export function getInitialValues(
  configs: AllOrgConfigs | undefined
): FormikType {
  const branding = configs?.Branding;
  if (branding === undefined || branding.disabled) {
    return {
      Branding: {
        disabled: false,
        primaryColor: OrgConfig.Branding.DefaultConfig.colors.primary[500],
        colorLogoPng: getDefaultLogo("colorLogoPng"),
        colorLogoSvg: getDefaultLogo("colorLogoSvg"),
        whiteLogoPng: getDefaultLogo("whiteLogoPng"),
        whiteLogoSvg: getDefaultLogo("whiteLogoSvg"),
      },
    };
  }

  return {
    Branding: {
      disabled: false,
      primaryColor: branding.colors.primary[500],
      colorLogoSvg: toLogo(branding, "colorSvg"),
      colorLogoPng: toLogo(branding, "colorPng"),
      whiteLogoSvg: toLogo(branding, "whiteSvg"),
      whiteLogoPng: toLogo(branding, "whitePng"),
    },
  };
}

function getLogoName(logoUrl: string): string {
  return logoUrl.split("/").pop() ?? logoUrl;
}

export function useUpdateBranding() {
  const env = useEnv();
  const token = useAccessToken();

  return {
    updateBranding: (formik: OrganizationFormSchema) =>
      updateBranding({
        token,
        env,
        formik,
      }),
  };
}

export async function updateBranding(args: {
  token: AuthData<string>;
  env: Env;
  formik: OrganizationFormSchema;
}): Promise<void> {
  const { organizationConfigs, path } = args.formik;
  const branding = organizationConfigs.Branding;
  const { token, env } = args;

  if (token.status !== Status.OK) {
    throw new Error("Missing access token");
  }

  // Upload any new logos
  if (branding.colorLogoSvg?.type === "new")
    await uploadLogo({
      env,
      orgPath: path,
      token: token.data,
      logo: branding.colorLogoSvg,
    });

  if (branding.colorLogoPng?.type === "new")
    await uploadLogo({
      env,
      orgPath: path,
      token: token.data,
      logo: branding.colorLogoPng,
    });

  if (branding.whiteLogoSvg?.type === "new")
    await uploadLogo({
      env,
      orgPath: path,
      token: token.data,
      logo: branding.whiteLogoSvg,
    });

  if (branding.whiteLogoPng?.type === "new")
    await uploadLogo({
      env,
      orgPath: path,
      token: token.data,
      logo: branding.whiteLogoPng,
    });

  // update branding
  const response = await fetch(
    `${env.REACT_APP_ORGANIZATION_SERVICE_URL}/config/v1/${path}/branding`,
    {
      method: "POST",
      body: JSON.stringify(toPayload(env, args.formik)),
      headers: {
        Authorization: `Bearer ${token.data}`,
      },
    }
  );

  if (response.status !== 200) {
    throw new Error("Error updating branding config", {
      cause: response.statusText,
    });
  }
}

/**
 * Helpers functions
 */

type UploadLogoArgs = {
  env: Env;
  token: string;
  orgPath: string;
  logo: Logo.NewLogo;
};
const UploadResponseSchema = z.object({
  url: z.string(),
});

async function uploadLogo({
  env,
  token,
  orgPath,
  logo,
}: UploadLogoArgs): Promise<void> {
  const path = Logo.toPath(orgPath, logo);
  const response = await fetch(
    `${env.REACT_APP_ORGANIZATION_SERVICE_URL}/generateUploadUrl`,
    {
      method: "POST",
      body: JSON.stringify({
        path,
      }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  const { url } = UploadResponseSchema.parse(await response.json());
  const file = await Logo.getFile(logo);
  const uploadResponse = await fetch(url, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": logo.imageType === "png" ? "image/png" : "image/svg+xml",
    },
  });

  if (!uploadResponse.ok) {
    throw new Error("Failed to upload logo");
  }
}

function toPayload(
  env: Env,
  formik: OrganizationFormSchema
): OrgConfig.Branding.Config {
  return {
    name: formik.name,
    message: {
      footer: formik.emailFooter ?? "",
    },
    colors: {
      primary: ColorPalette.generateColorPalette(
        formik.organizationConfigs.Branding.primaryColor
      ),
    },
    logos: {
      colorSvg: Logo.toUrl(
        env,
        formik.path,
        formik.organizationConfigs.Branding.colorLogoSvg
      ),
      whiteSvg: Logo.toUrl(
        env,
        formik.path,
        formik.organizationConfigs.Branding.whiteLogoSvg
      ),
      colorPng: Logo.toUrl(
        env,
        formik.path,
        formik.organizationConfigs.Branding.colorLogoPng
      ),
      whitePng: Logo.toUrl(
        env,
        formik.path,
        formik.organizationConfigs.Branding.whiteLogoPng
      ),
    },
  };
}

export function getDefaultLogo(type: keyof Logos): Logo.DefaultLogo {
  switch (type) {
    case "colorLogoPng":
      return {
        type: "default",
        imageType: "png",
        url: OrgConfig.Branding.DefaultConfig.logos.colorPng,
      };
    case "colorLogoSvg":
      return {
        type: "default",
        imageType: "svg",
        url: OrgConfig.Branding.DefaultConfig.logos.colorSvg,
      };
    case "whiteLogoPng":
      return {
        type: "default",
        imageType: "png",
        url: OrgConfig.Branding.DefaultConfig.logos.whitePng,
      };
    case "whiteLogoSvg":
      return {
        type: "default",
        imageType: "svg",
        url: OrgConfig.Branding.DefaultConfig.logos.whiteSvg,
      };
  }
}

export function toLogo(
  branding: OrgConfig.Branding.Config,
  logo: keyof OrgConfig.Branding.Logos
): Logo.DefaultLogo | Logo.ExistingLogo {
  switch (logo) {
    case "colorPng": {
      if (
        OrgConfig.Branding.DefaultConfig.logos.colorPng ===
        branding.logos.colorPng
      ) {
        return getDefaultLogo("colorLogoPng");
      }
      return {
        type: "existing",
        imageType: "svg",
        name: getLogoName(branding.logos.colorPng),
        url: branding.logos.colorPng,
      };
    }
    case "colorSvg": {
      if (
        OrgConfig.Branding.DefaultConfig.logos.colorSvg ===
        branding.logos.colorSvg
      ) {
        return getDefaultLogo("colorLogoSvg");
      }
      return {
        type: "existing",
        imageType: "svg",
        name: getLogoName(branding.logos.colorSvg),
        url: branding.logos.colorSvg,
      };
    }
    case "whitePng": {
      if (
        OrgConfig.Branding.DefaultConfig.logos.whitePng ===
        branding.logos.whitePng
      ) {
        return getDefaultLogo("whiteLogoPng");
      }
      return {
        type: "existing",
        imageType: "png",
        name: getLogoName(branding.logos.whitePng),
        url: branding.logos.whitePng,
      };
    }
    case "whiteSvg": {
      if (
        OrgConfig.Branding.DefaultConfig.logos.whiteSvg ===
        branding.logos.whiteSvg
      ) {
        return getDefaultLogo("whiteLogoSvg");
      }
      return {
        type: "existing",
        imageType: "svg",
        name: getLogoName(branding.logos.whiteSvg),
        url: branding.logos.whiteSvg,
      };
    }
  }
}
