import { ReactiveVar } from "@apollo/client";
import * as OrgConfig from "@avela/organization-config-sdk";
import { Branding } from "src/schemas/Branding";
import { z } from "zod";

export type WindowWithAuth = Window & {
  avelaAuth: AvelaAuth;
  avelaOrganization: ReactiveVar<string | undefined>;
  isParent: ReactiveVar<boolean | undefined>;
  hasExploreSavedSchools: ReactiveVar<boolean | undefined>;
  loginMethods: ReactiveVar<OrgConfig.Login.LoginMethod[] | undefined>;
  page: ReactiveVar<Page | undefined>;
  hide: ReactiveVar<readonly string[]>;
  accountLookupUrl: ReactiveVar<string | undefined>;
  branding: ReactiveVar<Branding | undefined>;
};

export type AvelaAuth = {
  loginWithSSO: (identityProvider: string) => Promise<void>;
  loginWithEmail: (email: string, password: string) => Promise<void>;
  startLoginWithPhone: (phone: string) => Promise<void>;
  loginWithPhone: (phone: string, code: string) => Promise<void>;
  signup: (email: string, password: string) => Promise<void>;
  changePassword: (email: string) => Promise<void>;
};

export enum Methods {
  Email = "email",
  Phone = "phone",
}

export enum Pages {
  Login = "login",
  Signup = "signup",
  ForgotPassword = "forgot-password",
}

export function safeParsePageWithDefault(obj: unknown): Page {
  const result = PageSchema.safeParse(obj);
  if (!result.success) {
    // default page
    return LoginPage(Methods.Email);
  }

  return result.data;
}

const LoginPageSchema = z.object({
  page: z.literal(Pages.Login),
  method: z.nativeEnum(Methods),
});
export type LoginPageType = z.infer<typeof LoginPageSchema>;
export function LoginPage(method: Methods): LoginPageType {
  return {
    page: Pages.Login,
    method,
  };
}

const SignupPageSchema = z.object({
  page: z.literal(Pages.Signup),
  method: z.nativeEnum(Methods),
});
export type SignupPageType = z.infer<typeof SignupPageSchema>;
export function SignupPage(method: Methods): SignupPageType {
  return {
    page: Pages.Signup,
    method,
  };
}

const ForgotPasswordPageSchema = z.object({
  page: z.literal(Pages.ForgotPassword),
});
export type ForgotPasswordPageType = z.infer<typeof ForgotPasswordPageSchema>;
export function ForgotPasswordPage(): ForgotPasswordPageType {
  return {
    page: Pages.ForgotPassword,
  };
}

const PageSchema = z.union([
  LoginPageSchema,
  SignupPageSchema,
  ForgotPasswordPageSchema,
]);
export type Page = z.infer<typeof PageSchema>;
export type LoginProps = {
  page: Page;
  switchPage: (page: Page) => void;
};

export enum HideFlag {
  SwitchPage = "switch-page",
  SwitchMethod = "switch-method",
  ForgotPassword = "forgot-password",
}

function isHideFlag(value: string): value is HideFlag {
  return Object.values(HideFlag).includes(value as HideFlag);
}

const HideFlagsSchema = z
  .object({
    hide: z.string().transform((value) => {
      return value
        .split(",")
        .map((v) => v.trim())
        .filter(isHideFlag);
    }),
  })
  .transform(({ hide }) => hide);
type HideFlags = z.infer<typeof HideFlagsSchema>;

export function safeParseHide(obj: unknown): HideFlags {
  const result = HideFlagsSchema.safeParse(obj);
  if (!result.success) {
    return [];
  }

  return result.data;
}

const AccountLookupUrlSchema = z.object({
  accountLookupUrl: z.string(),
});

export function safeParseAccountLookupUrl(obj: unknown): string | undefined {
  const result = AccountLookupUrlSchema.safeParse(obj);
  if (result.success) {
    return result.data.accountLookupUrl;
  }

  return undefined;
}
