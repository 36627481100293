import { gql } from "@apollo/client";
import { Button, ButtonProps } from "@chakra-ui/react";
import React from "react";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useAuth0ClientId } from "src/hooks/useAuth0ClientId";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import useUser, { EmailVerificationOverride } from "src/hooks/useUser";
import { Status } from "src/types/authData";
import * as GQL from "src/types/graphql";

const RESEND_EMAIL_VERIFICATION = gql`
  mutation ResendEmailVerification(
    $user_id: String!
    $client_id: String
    $secondaryIdentity: SecondaryIdentity
  ) {
    resendEmailVerification(
      request: {
        userId: $user_id
        secondaryIdentity: $secondaryIdentity
        clientId: $client_id
      }
    ) {
      message
      path
      result
    }
  }
`;

type Props = ButtonProps & {
  emailVerificationOverrider?: EmailVerificationOverride;
};
export const ResendEmailVerificationButton: React.FC<Props> = (props) => {
  const [resend, { remoteData }] = useRemoteDataMutation<
    GQL.ResendEmailVerification,
    GQL.ResendEmailVerificationVariables
  >(RESEND_EMAIL_VERIFICATION);
  const user = useUser();
  const toast = useAvelaToast();
  const clientIdRD = useAuth0ClientId();

  let secondaryIdentity: GQL.SecondaryIdentity = {};
  if (props.emailVerificationOverrider?.type === "secondary-identity") {
    secondaryIdentity = {
      userId: props.emailVerificationOverrider.userId,
      provider: props.emailVerificationOverrider.provider,
    };
  }

  return (
    <Button
      variant="outline"
      width="100%"
      isLoading={remoteData.isLoading()}
      onClick={async () => {
        if (
          (user.status !== Status.OK && user.status !== Status.NOT_VERIFIED) ||
          !clientIdRD.hasData()
        ) {
          toast.error({ title: "Unable to resend verification link" });
          return;
        }
        try {
          await resend({
            variables: {
              user_id: user.data.id,
              client_id: clientIdRD.data.clientId,
              secondaryIdentity,
            },
          });
          toast({ title: "Link resent", status: "info", isClosable: true });
        } catch (err: unknown) {
          console.error(err);
          toast.error({ title: "Unable to resend verification link" });
        }
      }}
    >
      Resend
    </Button>
  );
};
