import { Flex, Grid, GridItem } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import { useBranding } from "src/components/Providers/BrandingProvider";
import { WeglotInject } from "src/plugins/weglot";
import { WEGLOT_APPLY_CLASS } from "src/plugins/weglot/constants";
import { Header } from "./Header";
import { FormTemplateHintProvider } from "./hint";
import { getWhiteLogo } from "src/schemas/Branding";

export const Layout = () => {
  const branding = useBranding();

  return (
    <WeglotInject>
      <FormTemplateHintProvider>
        <Grid
          width="100vw"
          overflow="hidden"
          sx={{
            // Use `dvh` where supported, and `vh` elsewhere.
            height: "100vh",
            "&": { height: "100dvh" },
          }}
          templateRows="2.75rem 1fr"
          templateAreas='"header""main"'
        >
          <Flex gridArea="header">
            <Header logo={getWhiteLogo(branding)} />
          </Flex>

          <GridItem
            area="main"
            bg="gray.100"
            className={WEGLOT_APPLY_CLASS}
            display="grid"
            minHeight={0}
            width="100%"
          >
            <Outlet />
          </GridItem>
        </Grid>
      </FormTemplateHintProvider>
    </WeglotInject>
  );
};
