import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import React, { useRef } from "react";
import { RiUploadLine } from "react-icons/ri";
import { useBulkUploadInput } from "src/components/Inputs/BulkUploadInput/useBulkUploadInput";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { useAvelaToast } from "src/hooks/useAvelaToast";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { z } from "zod";
import { IMPORT_LOTTERY_ORDER } from "../graphql/mutations";
import { GET_FORMS_BY_FORM_TEMPLATE } from "../graphql/queries";
import { useIsOfferEnabled } from "src/hooks/useIsOfferEnabled";
import { useOrganization } from "src/hooks/useOrganization";

const FormListImport = z.object({
  "App ID": z.string().min(1),
  "School ID": z.string().nullish(),
});

export type FormListImportRow = z.infer<typeof FormListImport>;

function isValidFormListImport(data: unknown): data is FormListImportRow[] {
  return (
    Array.isArray(data) && data.every((row) => isValidFormListImportRow(row))
  );
}

function isValidFormListImportRow(data: unknown): data is FormListImportRow {
  const result = FormListImport.safeParse(data);
  if (!result.success) {
    console.error(result.error);
  }
  return result.success;
}

const LotteryOrderImport = z.object({
  "App ID": z.string().min(1),
  "School ID": z.string().min(1),
  Order: z
    .string()
    .regex(/(^\d+\.?\d*$)|(^\.\d+$)/)
    .transform(Number),
});

export type LotteryOrderImportRow = z.infer<typeof LotteryOrderImport>;

function isValidLotteryOrderImport(
  data: unknown
): data is LotteryOrderImportRow[] {
  return (
    Array.isArray(data) &&
    data.filter((row) => !isValidLotteryOrderImportRow(row)).length === 0
  );
}

function isValidLotteryOrderImportRow(
  data: unknown
): data is LotteryOrderImportRow {
  const result = LotteryOrderImport.safeParse(data);
  if (!result.success) {
    console.error(result.error);
  }
  return result.success;
}

interface ImportMenuInputProps {
  onFormListImported: (data: FormListImportRow[]) => void;
  formTemplate?: GQL.GetFormTemplatesByEnrollmentPeriod_form_template;
}

export const ImportMenuInput: React.FC<ImportMenuInputProps> = ({
  onFormListImported,
  formTemplate,
}) => {
  const organization = useOrganization();
  const formListInput = useRef<HTMLInputElement>(null);
  const lotteryOrderInput = useRef<HTMLInputElement>(null);
  const toast = useAvelaToast({ position: "bottom" });

  const isOfferEnabled = useIsOfferEnabled(organization, formTemplate);

  const { handleInputChange: handleFormListImport } = useBulkUploadInput(
    isValidFormListImport,
    (data: FormListImportRow[]) => {
      onFormListImported(data);
    }
  );

  const [importLotteryOrder] = useRemoteDataMutation<
    GQL.ImportLotteryOrderVariables,
    GQL.ImportLotteryOrderVariables
  >(IMPORT_LOTTERY_ORDER);

  const { handleInputChange: handleLotteryOrderImport } = useBulkUploadInput(
    isValidLotteryOrderImport,
    (data: LotteryOrderImportRow[]) => {
      const updates: GQL.form_school_rank_updates[] = data.map((row) => {
        return {
          where: {
            form_id: { _eq: row["App ID"] },
            school_id: { _eq: row["School ID"] },
          },
          _set: { lottery_order: row.Order },
        };
      });

      importLotteryOrder({
        variables: { updates: updates },
        refetchQueries: [GET_FORMS_BY_FORM_TEMPLATE],
      })
        .then(() => {
          toast({
            title: `Lottery order imported`,
            status: "info",
          });
        })
        .catch((error) => {
          toast.error({
            title: "Unable to import lottery order",
            description: error.message,
          });
          console.error(error);
        });
    }
  );

  const handleClickFormList = () => {
    formListInput.current?.click();
  };

  const handleClickLotteryOrder = () => {
    lotteryOrderInput.current?.click();
  };

  return (
    <>
      <Menu>
        <MenuButton as={Button} variant="outline">
          <Flex gap={2} lineHeight={4}>
            <Icon as={RiUploadLine} />
            <Text>Import</Text>
            <Icon as={ChevronDownIcon} />
          </Flex>
        </MenuButton>
        <MenuList>
          <MenuItem onClick={handleClickFormList}>Selection list</MenuItem>
          <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
            {isOfferEnabled && (
              <MenuItem onClick={handleClickLotteryOrder}>
                Lottery order
              </MenuItem>
            )}
          </WithRequiredHasuraRoles>
        </MenuList>
      </Menu>
      <Input
        type="file"
        accept=".csv"
        ref={formListInput}
        onChange={handleFormListImport}
        display="none"
      />

      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}>
        {isOfferEnabled && (
          <Input
            type="file"
            accept=".csv"
            ref={lotteryOrderInput}
            onChange={handleLotteryOrderImport}
            display="none"
          />
        )}
      </WithRequiredHasuraRoles>
    </>
  );
};
