import { ApolloError } from "@apollo/client";
import { identity } from "lodash";
import { z } from "zod";
import { useFlags, useFlagsmithLoading } from "flagsmith/react";
import { FeatureFlag } from "./featureFlag";
import * as OrgConfig from "@avela/organization-config-sdk";
import _ from "lodash";

/**
 * This is an abstraction of remote data, meaning, the data will be transported over the wire.
 * Inspired by RemoteData in Elm (see this blog post for more info: http://blog.jenkster.com/2016/06/how-elm-slays-a-ui-antipattern.html)
 * Copied almost verbatimly from: https://github.com/abadi199/ts-remotedata
 */
export enum RemoteDataKind {
  NotAsked = 1,
  Loading = 2,
  Reloading = 3,
  Success = 4,
  Failure = 5,
  FailureWithData = 6,
}

export interface IRemoteData<E, T> {
  kind: RemoteDataKind;
  isNotAsked(): this is NotAsked<E, T>;
  isLoading(): this is Loading<E, T> | Reloading<E, T>;
  hasError(): this is Failure<E, T> | FailureWithData<E, T>;
  hasData(): this is Reloading<E, T> | Success<E, T> | FailureWithData<E, T>;
  map<U>(f: (data: T) => U): RemoteData<E, U>;
  andThen<U>(f: (data: T) => RemoteData<E, U>): RemoteData<E, U>;
  withDefault<T2>(defaultData: T2): T | T2;
  mapError<E2>(f: (error: E) => E2): RemoteData<E2, T>;
  withDefaultError(error: E): E;
  do(f: (data: T) => void): RemoteData<E, T>;
  catch(f: (error: E) => void): RemoteData<E, T>;
  toNullable(): T | null;
  recover<E2>(f: (error: E) => RemoteData<E2, T>): RemoteData<E2, T>;
  castError<E2>(): RemoteData<E2, T>;
}
class NotAsked<E, T> implements IRemoteData<E, T> {
  readonly kind = RemoteDataKind.NotAsked;
  isNotAsked = (): this is NotAsked<E, T> => true;
  hasData(): this is Reloading<E, T> | Success<E, T> | FailureWithData<E, T> {
    return false;
  }
  isLoading = (): this is Loading<E, T> | Reloading<E, T> => false;
  hasError = (): this is Failure<E, T> | FailureWithData<E, T> => false;
  map<U>(_f: (data: T) => U): RemoteData<E, U> {
    return notAsked<E, U>();
  }
  andThen<U>(f: (data: T) => RemoteData<E, U>): RemoteData<E, U> {
    return notAsked<E, U>();
  }
  withDefault<T2>(defaultData: T2) {
    return defaultData;
  }
  mapError<E2>(_f: (error: E) => E2): RemoteData<E2, T> {
    return notAsked<E2, T>();
  }
  withDefaultError(error: E): E {
    return error;
  }
  do(_: (data: T) => void): RemoteData<E, T> {
    return this;
  }
  toNullable(): T | null {
    return null;
  }
  recover<E2>(_f: (error: E) => RemoteData<E2, T>): RemoteData<E2, T> {
    return notAsked<E2, T>();
  }
  catch(_f: (error: E) => void): RemoteData<E, T> {
    return this;
  }
  castError<E2>(): RemoteData<E2, T> {
    return this.mapError<E2>(_.identity);
  }
}

export function notAsked<E, T>(): NotAsked<E, T> {
  return new NotAsked<E, T>();
}

class Loading<E, T> implements IRemoteData<E, T> {
  readonly kind = RemoteDataKind.Loading;
  isNotAsked = (): this is NotAsked<E, T> => false;
  isLoading = (): this is Loading<E, T> | Reloading<E, T> => true;
  hasData(): this is Reloading<E, T> | Success<E, T> | FailureWithData<E, T> {
    return false;
  }
  hasError = (): this is Failure<E, T> | FailureWithData<E, T> => false;
  map<U>(_f: (data: T) => U): RemoteData<E, U> {
    return loading();
  }
  andThen<U>(f: (data: T) => RemoteData<E, U>): RemoteData<E, U> {
    return loading();
  }
  withDefault<T2>(defaultData: T2) {
    return defaultData;
  }
  mapError<E2>(_f: (error: E) => E2): RemoteData<E2, T> {
    return loading();
  }
  withDefaultError(error: E): E {
    return error;
  }
  do(_: (data: T) => void): RemoteData<E, T> {
    return this;
  }
  toNullable(): T | null {
    return null;
  }
  recover<E2>(_f: (error: E) => RemoteData<E2, T>): RemoteData<E2, T> {
    return loading();
  }
  catch(_f: (error: E) => void): RemoteData<E, T> {
    return this;
  }
  castError<E2>(): RemoteData<E2, T> {
    return this.mapError<E2>(_.identity);
  }
}

class Reloading<E, T> implements IRemoteData<E, T> {
  readonly kind = RemoteDataKind.Reloading;
  constructor(public data: T) {}
  isNotAsked = (): this is NotAsked<E, T> => false;
  isLoading = (): this is Loading<E, T> | Reloading<E, T> => true;
  hasData(): this is Reloading<E, T> | Success<E, T> | FailureWithData<E, T> {
    return true;
  }
  hasError = (): this is Failure<E, T> | FailureWithData<E, T> => false;
  map<U>(f: (data: T) => U): RemoteData<E, U> {
    return new Reloading<E, U>(f(this.data));
  }
  andThen<U>(f: (data: T) => RemoteData<E, U>): RemoteData<E, U> {
    return loading(f(this.data));
  }
  withDefault<T2>(defaultData: T2) {
    return this.data;
  }
  mapError<E2>(_f: (error: E) => E2): RemoteData<E2, T> {
    return new Reloading<E2, T>(this.data);
  }
  withDefaultError(error: E): E {
    return error;
  }
  do(f: (data: T) => void): RemoteData<E, T> {
    f(this.data);
    return this;
  }
  toNullable(): T | null {
    return this.data;
  }
  recover<E2>(_f: (error: E) => RemoteData<E2, T>): RemoteData<E2, T> {
    return new Reloading<E2, T>(this.data);
  }
  catch(_f: (error: E) => void): RemoteData<E, T> {
    return this;
  }
  castError<E2>(): RemoteData<E2, T> {
    return this.mapError<E2>(_.identity);
  }
}

export function loading<E, T>(
  previous: RemoteData<E, T> | null = null
): RemoteData<E, T> {
  if (previous === null) {
    return new Loading();
  }
  switch (previous.kind) {
    case RemoteDataKind.Failure:
      return new Loading();
    case RemoteDataKind.FailureWithData:
      return new Reloading(previous.data);
    case RemoteDataKind.Loading:
      return previous;
    case RemoteDataKind.NotAsked:
      return new Loading();
    case RemoteDataKind.Reloading:
      return previous;
    case RemoteDataKind.Success:
      return new Reloading(previous.data);
  }
}

class Success<E, T> implements IRemoteData<E, T> {
  constructor(public data: T) {}
  readonly kind = RemoteDataKind.Success;
  isNotAsked = (): this is NotAsked<E, T> => false;
  isLoading = (): this is Loading<E, T> | Reloading<E, T> => false;
  hasData(): this is Reloading<E, T> | Success<E, T> | FailureWithData<E, T> {
    return true;
  }
  hasError = (): this is Failure<E, T> | FailureWithData<E, T> => false;
  map<U>(f: (data: T) => U): RemoteData<E, U> {
    return success(f(this.data));
  }
  andThen<U>(f: (data: T) => RemoteData<E, U>): RemoteData<E, U> {
    return f(this.data);
  }
  withDefault<T2>(_data: T2) {
    return this.data;
  }
  mapError<E2>(_f: (error: E) => E2): RemoteData<E2, T> {
    return success(this.data);
  }
  withDefaultError(error: E): E {
    return error;
  }
  do(f: (data: T) => void): RemoteData<E, T> {
    f(this.data);
    return this;
  }
  toNullable(): T | null {
    return this.data;
  }
  recover<E2>(f: (error: E) => RemoteData<E2, T>): RemoteData<E2, T> {
    return success(this.data);
  }
  catch(_f: (error: E) => void): RemoteData<E, T> {
    return this;
  }
  castError<E2>(): RemoteData<E2, T> {
    return this.mapError<E2>(_.identity);
  }
}
export function success<E, T>(value: T): RemoteData<E, T> {
  return new Success(value);
}

class Failure<E, T> implements IRemoteData<E, T> {
  constructor(public error: E) {}
  readonly kind = RemoteDataKind.Failure;
  isNotAsked = (): this is NotAsked<E, T> => false;
  isLoading = (): this is Loading<E, T> | Reloading<E, T> => false;
  hasData(): this is Reloading<E, T> | Success<E, T> | FailureWithData<E, T> {
    return false;
  }
  hasError(): this is Failure<E, T> | FailureWithData<E, T> {
    return true;
  }
  map<U>(_f: (data: T) => U): RemoteData<E, U> {
    return failure(this.error);
  }
  andThen<U>(f: (data: T) => RemoteData<E, U>): RemoteData<E, U> {
    return failure(this.error);
  }
  withDefault<T2>(defaultData: T2) {
    return defaultData;
  }
  mapError<E2>(f: (error: E) => E2): RemoteData<E2, T> {
    return failure(f(this.error));
  }
  withDefaultError(_error: E): E {
    return this.error;
  }
  do(_: (data: T) => void): RemoteData<E, T> {
    return this;
  }
  toNullable(): T | null {
    return null;
  }
  recover<E2>(f: (error: E) => RemoteData<E2, T>): RemoteData<E2, T> {
    return f(this.error);
  }
  catch(f: (error: E) => void): RemoteData<E, T> {
    f(this.error);
    return this;
  }
  castError<E2>(): RemoteData<E2, T> {
    return this.mapError<E2>(_.identity);
  }
}

class FailureWithData<E, T> implements IRemoteData<E, T> {
  constructor(public error: E, public data: T) {}
  readonly kind = RemoteDataKind.FailureWithData;
  isNotAsked = (): this is NotAsked<E, T> => false;
  isLoading = (): this is Loading<E, T> | Reloading<E, T> => false;
  hasData(): this is Reloading<E, T> | Success<E, T> | FailureWithData<E, T> {
    return true;
  }
  hasError(): this is Failure<E, T> | FailureWithData<E, T> {
    return true;
  }
  map<U>(f: (data: T) => U): RemoteData<E, U> {
    return new FailureWithData(this.error, f(this.data));
  }
  andThen<U>(f: (data: T) => RemoteData<E, U>): RemoteData<E, U> {
    return failure(this.error, f(this.data));
  }
  withDefault<T2>(_defaultData: T2) {
    return this.data;
  }
  mapError<E2>(f: (error: E) => E2): RemoteData<E2, T> {
    return new FailureWithData(f(this.error), this.data);
  }
  withDefaultError(_error: E): E {
    return this.error;
  }
  do(f: (data: T) => void): RemoteData<E, T> {
    f(this.data);
    return this;
  }
  toNullable(): T | null {
    return this.data;
  }
  recover<E2>(f: (error: E) => RemoteData<E2, T>): RemoteData<E2, T> {
    const next = f(this.error);
    if (next.hasData() && next.hasError()) {
      return new FailureWithData(next.error, next.data);
    } else {
      return next;
    }
  }
  catch(f: (error: E) => void): RemoteData<E, T> {
    f(this.error);
    return this;
  }
  castError<E2>(): RemoteData<E2, T> {
    return this.mapError<E2>(_.identity);
  }
}

export function failure<E, T>(
  error: E,
  previous: RemoteData<E, T> | null = null
): RemoteData<E, T> {
  if (previous === null) {
    return new Failure(error);
  }
  switch (previous.kind) {
    case RemoteDataKind.Failure:
      return new Failure(error);
    case RemoteDataKind.FailureWithData:
      return new FailureWithData(error, previous.data);
    case RemoteDataKind.Loading:
      return new Failure(error);
    case RemoteDataKind.NotAsked:
      return new Failure(error);
    case RemoteDataKind.Reloading:
      return new FailureWithData(error, previous.data);
    case RemoteDataKind.Success:
      return new FailureWithData(error, previous.data);
  }
}

export type RemoteData<E, T> =
  | NotAsked<E, T>
  | Loading<E, T>
  | Reloading<E, T>
  | Success<E, T>
  | Failure<E, T>
  | FailureWithData<E, T>;

interface ApolloResult<T> {
  data?: T;
  previousData?: T;
  error?: ApolloError;
  loading: boolean;
}
export function fromApolloResult<T>({
  error,
  loading: dataLoading,
  data,
  previousData,
}: ApolloResult<T>): RemoteData<ApolloError, T> {
  // Error need to be checked before loading since loading will still be `true` in case of error.
  if (error) {
    return failure(error, previousData ? success(previousData) : undefined);
  }

  if (dataLoading) {
    return loading(previousData ? success(previousData) : undefined);
  }

  if (data === undefined) {
    return notAsked();
  }

  return success(data);
}

/**
 * Apply a function over 2 remote datas.
 *
 * Use this function if you need to combine 2 remote datas into a single remote data.
 *
 * Example:
 * ```js
 * RemoteData.map2(
 *  studentsData,
 *  enrollmentPeriodsData,
 *  (students, enrollmentPeriods) => {
 *    // combine the data into a single object
 *    return { students, enrollmentPeriods };
 * })
 * ```
 *
 * @param a first RemoteData
 * @param b second RemoteData
 * @param f a function that takes `a` and `b` as argument, and transform them into something new.
 * @returns a new RemoteData with return value of `f` as the data
 */
export function map2<E, A, B, T>(
  a: RemoteData<E, A>,
  b: RemoteData<E, B>,
  f: (a: A, b: B) => T
): RemoteData<E, T> {
  if (a.kind === RemoteDataKind.Failure) {
    return failure(a.error);
  }

  if (b.kind === RemoteDataKind.Failure) {
    return failure(b.error);
  }

  if (a.kind === RemoteDataKind.FailureWithData && b.hasData()) {
    return failure(a.error, success(f(a.data, b.data)));
  }

  if (b.kind === RemoteDataKind.FailureWithData && a.hasData()) {
    return failure(b.error, success(f(a.data, b.data)));
  }

  if (a.kind === RemoteDataKind.Loading || b.kind === RemoteDataKind.Loading) {
    return loading();
  }

  if (a.kind === RemoteDataKind.Reloading && b.hasData()) {
    return loading(success(f(a.data, b.data)));
  }

  if (b.kind === RemoteDataKind.Reloading && a.hasData()) {
    return loading(success(f(a.data, b.data)));
  }

  if (
    a.kind === RemoteDataKind.NotAsked ||
    b.kind === RemoteDataKind.NotAsked
  ) {
    return notAsked();
  }

  return success(f(a.data, b.data));
}

/**
 * Merge 2 RemoteData into a tuple with 2 data
 * @param a first RemoteData
 * @param b second RemoteData
 * @returns
 */
export function toTuple<EA, EB, A, B>(
  a: RemoteData<EA, A>,
  b: RemoteData<EB, B>
): RemoteData<EA | EB, [A, B]> {
  return map2(
    a.mapError<EA | EB>(identity),
    b.mapError<EA | EB>(identity),
    (a, b) => [a, b]
  );
}

/**
 * Merge 3 RemoteData into a tuple with 3 data
 */
export function toTuple3<EA, EB, EC, A, B, C>(
  a: RemoteData<EA, A>,
  b: RemoteData<EB, B>,
  c: RemoteData<EC, C>
): RemoteData<EA | EB | EC, [A, B, C]> {
  const tuple: RemoteData<EA | EB | EC, [A, B]> = toTuple(a, b);
  return map2(tuple, c, ([aa, bb], cc) => [aa, bb, cc]);
}

/**
 * Merge 4 RemoteData into a tuple with 3 data
 */
export function toTuple4<EA, EB, EC, ED, A, B, C, D>(
  a: RemoteData<EA, A>,
  b: RemoteData<EB, B>,
  c: RemoteData<EC, C>,
  d: RemoteData<ED, D>
): RemoteData<EA | EB | EC | ED, [A, B, C, D]> {
  const tuple: RemoteData<EA | EB | EC | ED, [A, B, C]> = toTuple3(a, b, c);
  return map2(tuple, d, ([aa, bb, cc], dd) => [aa, bb, cc, dd]);
}

export function toTuple5<EA, EB, EC, ED, EE, A, B, C, D, E>(
  a: RemoteData<EA, A>,
  b: RemoteData<EB, B>,
  c: RemoteData<EC, C>,
  d: RemoteData<ED, D>,
  e: RemoteData<EE, E>
): RemoteData<EA | EB | EC | ED | EE, [A, B, C, D, E]> {
  const tuple: RemoteData<EA | EB | EC | ED | EE, [A, B, C, D]> = toTuple4(
    a,
    b,
    c,
    d
  );
  return map2(tuple, e, ([aa, bb, cc, dd], ee) => [aa, bb, cc, dd, ee]);
}
export function isRemoteData<E, T>(value: unknown): value is RemoteData<E, T> {
  return (
    value instanceof NotAsked ||
    value instanceof Loading ||
    value instanceof Success ||
    value instanceof Failure ||
    value instanceof Reloading ||
    value instanceof FailureWithData
  );
}

export function fromZod<T extends z.ZodSchema>(
  schema: T,
  data: unknown
): RemoteData<Error, z.infer<T>> {
  const result = schema.safeParse(data);
  if (result.success) {
    return success(result.data);
  }

  return failure(result.error);
}

type FlagsmithLoading = ReturnType<typeof useFlagsmithLoading>;
type FlagsmithData = ReturnType<typeof useFlags<FeatureFlag>>;
export function fromFlagsmith(
  loadingState: FlagsmithLoading,
  flags: FlagsmithData
): RemoteData<Error, FlagsmithData> {
  if (!loadingState) {
    return notAsked();
  }

  if (loadingState.error) {
    return failure(loadingState.error);
  }

  if (loadingState.isFetching || loadingState.isLoading) {
    return loading();
  }

  return success(flags);
}

export function fromRemoteConfig<T extends OrgConfig.Type>(
  remoteConfig: OrgConfig.RemoteConfig<T>
): RemoteData<Error, OrgConfig.Config<T>> {
  if (remoteConfig.status === "error") {
    return failure(remoteConfig.error);
  }

  if (remoteConfig.status === "loading") {
    return loading();
  }

  return success(remoteConfig.config);
}

/**
 * Combine a list of RemoteData of something into a RemoteData of a list of something.
 * If any of the remote data in the list is in loading/error state,
 * then it will produce a remote data of that loading/error state.
 */
export function combine<E, T>(list: RemoteData<E, T>[]): RemoteData<E, T[]> {
  const combined: RemoteData<E, T[]> = list.reduce(
    (previousValue: RemoteData<E, T[]>, currentValue: RemoteData<E, T>) => {
      return map2(previousValue, currentValue, (previousList, currentItem) => {
        return previousList.concat(currentItem);
      });
    },
    success([]) as RemoteData<E, T[]>
  );

  return combined;
}

// Add this interface to define the shape of REST API results
interface RestResult<E, T> {
  data?: T;
  error?: E;
  isLoading: boolean;
  isError: boolean;
}

/**
 * Converts a REST API result into a RemoteData object
 * @param param0 Object containing REST API result data
 * @returns RemoteData object representing the REST API state
 */
export function fromRestResult<E, T>({
  error,
  isLoading,
  isError,
  data,
}: RestResult<E, T>): RemoteData<E, T> {
  // Check for error first since we might have error and loading states simultaneously
  if (isError && error) {
    return failure(error);
  }

  if (isLoading) {
    return loading(data ? success(data) : undefined);
  }

  if (data === undefined) {
    return notAsked();
  }

  return success(data);
}
