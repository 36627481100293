import { Route } from "react-router-dom";
import { PreserveState } from "src/components/Navigation/PreserveState";
import { RequireUserPermissions } from "src/components/Permissions/RequireUserPermissions";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { Edit } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/Edit";
import {
  EditMessageTemplate,
  ListMessageTemplates,
} from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/AutomaticMessages";
import { ContentTab } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Content";
import { SettingsTab } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Settings";
import { StatusesTab } from "src/scenes/orgAdmin/enrollmentPeriods/scenes/FormTemplates/EditFormTemplateTabs/Statuses";
import * as Url from "src/services/url";
import {
  EnrollmentPeriodFormTemplates,
  EnrollmentPeriodGeneral,
  EnrollmentPeriodSchools,
  FormTemplateContent,
  FormTemplateSettings,
  FormTemplateStatuses,
  MessageTemplate,
} from "src/services/url/OrgAdmin";
import { HasuraRole } from "src/types/hasuraRole";
import { Enrollments } from ".";
import { EditEnrollmentPeriod } from "./Edit";
import { NewEnrollmentPeriod } from "./New";
import { EnrollmentSchoolsTab } from "./scenes/EnrollmentSchools";
import { FormTemplatesTab } from "./scenes/FormTemplates";
import { New as NewFormTemplate } from "./scenes/FormTemplates/New";
import { GeneralTab } from "./scenes/General";

export const EnrollmentPeriodRoutes = (
  <Route
    path={Url.OrgAdmin.Enrollments.indexPath}
    element={
      <PreserveState>
        <RequireUserPermissions permission="navigation.admin.enrollment_periods">
          <Enrollments />
        </RequireUserPermissions>
      </PreserveState>
    }
  >
    <Route
      path={Url.OrgAdmin.Enrollments.newPath}
      element={
        <RequireUserPermissions permission="enrollment_period:create">
          <NewEnrollmentPeriod />
        </RequireUserPermissions>
      }
    />
    <Route
      path={Url.OrgAdmin.Enrollments.editPath}
      element={
        <RequireUserPermissions permission="enrollment_period:update">
          <EditEnrollmentPeriod />
        </RequireUserPermissions>
      }
    >
      <Route
        path={EnrollmentPeriodGeneral.editPath}
        element={
          <RequireUserPermissions permission="enrollment_period:update">
            <GeneralTab />
          </RequireUserPermissions>
        }
      />
      <Route
        path={EnrollmentPeriodSchools.editPath}
        element={
          <RequireUserPermissions permission="enrollment_period:update">
            <EnrollmentSchoolsTab />
          </RequireUserPermissions>
        }
      />
      <Route
        path={EnrollmentPeriodFormTemplates.indexPath}
        element={
          <RequireUserPermissions permission="enrollment_period:update">
            <FormTemplatesTab />
          </RequireUserPermissions>
        }
      />
    </Route>
    <Route
      path={EnrollmentPeriodFormTemplates.newPath}
      element={
        <RequireUserPermissions permission="form_template:create">
          <NewFormTemplate />
        </RequireUserPermissions>
      }
    />
  </Route>
);

export const EditFormTemplateRoutes = (
  <Route
    path={EnrollmentPeriodFormTemplates.editPath}
    element={
      <RequireUserPermissions permission="form_template:update">
        <Edit />
      </RequireUserPermissions>
    }
  >
    {/**
     * These nested routes use absolute paths.
     * Nested routes with absolute paths only work if they are in the same component as the parent.
     * https://github.com/remix-run/react-router/issues/8035#issuecomment-931215483.
     * Moving them into another file requires migrating the "path" prop to relative routes.
     */}
    <Route
      path={FormTemplateContent.editPath}
      element={
        <RequireUserPermissions permission="form_template:update">
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
          >
            <ContentTab />
          </WithRequiredHasuraRoles>
        </RequireUserPermissions>
      }
    />
    <Route
      path={FormTemplateStatuses.editPath}
      element={
        <RequireUserPermissions permission="form_template:update">
          <StatusesTab />
        </RequireUserPermissions>
      }
    />
    <Route
      path={MessageTemplate.indexPath}
      element={
        <RequireUserPermissions permission="automatic_message:read">
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
          >
            <ListMessageTemplates />
          </WithRequiredHasuraRoles>
        </RequireUserPermissions>
      }
    />
    <Route
      path={MessageTemplate.editPath}
      element={
        <RequireUserPermissions permission="automatic_message:update">
          <WithRequiredHasuraRoles
            roles={[HasuraRole.ADMIN, HasuraRole.ORG_ADMIN]}
          >
            <EditMessageTemplate />
          </WithRequiredHasuraRoles>
        </RequireUserPermissions>
      }
    />
    <Route
      path={FormTemplateSettings.editPath}
      element={
        <RequireUserPermissions permission="form_template:update">
          <SettingsTab />
        </RequireUserPermissions>
      }
    />
  </Route>
);
