import Color from "color";
import * as OrgConfig from "@avela/organization-config-sdk";
import { Flex, Tooltip } from "@chakra-ui/react";
import { isValidHexColor } from "src/components/Inputs/ColorInput";

type Props = {
  color: string | undefined;
};
export function ColorPalette({ color }: Props) {
  const palette = generateColorPalette(color);
  return (
    <Flex>
      <ColorBox palette={palette} index="50" />
      <ColorBox palette={palette} index="100" />
      <ColorBox palette={palette} index="200" />
      <ColorBox palette={palette} index="300" />
      <ColorBox palette={palette} index="400" />
      <ColorBox palette={palette} index="500" />
      <ColorBox palette={palette} index="600" />
      <ColorBox palette={palette} index="700" />
      <ColorBox palette={palette} index="800" />
      <ColorBox palette={palette} index="900" />
    </Flex>
  );
}

function ColorBox({
  index,
  palette,
}: {
  index: keyof Palette;
  palette: Palette;
}) {
  return (
    <Tooltip label={palette[index]}>
      <Flex
        height="2rem"
        width="2rem"
        background={palette[index]}
        alignItems="center"
        justifyContent="center"
        fontSize="xs"
      ></Flex>
    </Tooltip>
  );
}
type Palette = OrgConfig.Branding.Colors["primary"];

function generate(color: Color<string>, baseLightness: number): Palette {
  try {
    if (color === undefined) {
      return emptyColor;
    }

    const darkSteps = 4;
    const lightSteps = 5;
    const config = {
      centerColor: color,
      darkest: {
        lightness: 10,
        rotate: 0,
        saturate: 0,
      },
      lightest: {
        lightness: 95,
        rotate: 0,
        saturate: 0,
      },
    };
    const lightnessStep =
      (config.lightest.lightness - baseLightness) / lightSteps;
    const darknessStep = (baseLightness - config.darkest.lightness) / darkSteps;

    const lightRotateStep = config.lightest.rotate / lightSteps;
    const darkRotateStep = config.darkest.rotate / darkSteps;

    const lightSaturateStep = config.lightest.saturate / lightSteps;
    const darkSaturateStep = config.darkest.saturate / darkSteps;

    return {
      50: Color(config.centerColor)
        .lightness(baseLightness + lightnessStep * 5)
        .rotate(lightRotateStep * 5)
        .saturate(lightSaturateStep * 5)
        .hex()
        .toLowerCase(),
      100: Color(config.centerColor)
        .lightness(baseLightness + lightnessStep * 4)
        .rotate(lightRotateStep * 4)
        .saturate(lightSaturateStep * 4)
        .hex()
        .toLowerCase(),
      200: Color(config.centerColor)
        .lightness(baseLightness + lightnessStep * 3)
        .rotate(lightRotateStep * 3)
        .saturate(lightSaturateStep * 3)
        .hex()
        .toLowerCase(),
      300: Color(config.centerColor)
        .lightness(baseLightness + lightnessStep * 2)
        .rotate(lightRotateStep * 2)
        .saturate(lightSaturateStep * 2)
        .hex()
        .toLowerCase(),
      400: Color(config.centerColor)
        .lightness(baseLightness + lightnessStep * 1)
        .rotate(lightRotateStep * 1)
        .saturate(lightSaturateStep * 1)
        .hex()
        .toLowerCase(),
      500: Color(config.centerColor)
        .lightness(baseLightness)
        .hex()
        .toLowerCase(),
      600: Color(config.centerColor)
        .lightness(baseLightness - darknessStep * 1)
        .rotate(darkRotateStep * 1)
        .saturate(darkSaturateStep * 1)
        .hex()
        .toLowerCase(),
      700: Color(config.centerColor)
        .lightness(baseLightness - darknessStep * 2)
        .rotate(darkRotateStep * 2)
        .saturate(darkSaturateStep * 2)
        .hex()
        .toLowerCase(),
      800: Color(config.centerColor)
        .lightness(baseLightness - darknessStep * 3)
        .rotate(darkRotateStep * 3)
        .saturate(darkSaturateStep * 3)
        .hex()
        .toLowerCase(),
      900: Color(config.centerColor)
        .lightness(baseLightness - darknessStep * 4)
        .rotate(darkRotateStep * 4)
        .saturate(darkSaturateStep * 4)
        .hex()
        .toLowerCase(),
    };
  } catch (error) {
    return emptyColor;
  }
}

export function generateColorPalette(color: string | undefined): Palette {
  try {
    if (color === undefined || !isValidHexColor(color)) {
      return emptyColor;
    }

    const base = Color(color);
    return generate(base, base.lightness());
  } catch (error) {
    return emptyColor;
  }
}

const emptyColor = {
  "50": "",
  "100": "",
  "200": "",
  "300": "",
  "400": "",
  "500": "",
  "600": "",
  "700": "",
  "800": "",
  "900": "",
};
