import {
  Icon,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { RiPencilLine } from "react-icons/ri";
import { NavLink } from "react-router-dom";
import * as Url from "src/services/url";
import { DeleteOrganizationButton } from "./DeleteButton";

type Organization = { id: uuid; name: string; path: string; status: string };
interface ListProps {
  organizations: Organization[];
  onChange: () => void;
}
export const List = ({ organizations, onChange }: ListProps) => {
  if (organizations.length === 0) {
    return <span>There are no organizations</span>;
  }

  return (
    <TableContainer>
      <Table size="sm">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Organization Name</Th>
            <Th>Path</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {organizations.map((organization) => (
            <Tr key={organization.id}>
              <Td>{organization.id}</Td>
              <Td>{organization.name}</Td>
              <Td>{organization.path}</Td>
              <Td>{organization.status}</Td>
              <Td>
                <IconButton
                  as={NavLink}
                  to={Url.Admin.Organizations.edit(organization.id)}
                  aria-label={`Edit ${organization.name}`}
                  icon={<Icon as={RiPencilLine} />}
                  variant="ghost"
                />
                <DeleteOrganizationButton
                  id={organization.id}
                  name={organization.name}
                  onDelete={onChange}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
