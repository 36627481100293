import { Flex, Skeleton, useToast } from "@chakra-ui/react";
import { useNavigate, useParams } from "react-router";
import { NotFound } from "src/components/Feedback/NotFound";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import {
  useLazyRemoteDataQuery,
  useRemoteDataQuery,
} from "src/hooks/useRemoteDataQuery";
import * as Url from "src/services/url";
import * as GQL from "src/types/graphql";
import { OrganizationForm } from "./Form";
import { OrganizationFormSchema } from "./types/formikSchema";
import {
  CREATE_ANNOUNCEMENT,
  UPDATE_ANNOUNCEMENT,
  UPDATE_ORGANIZATION,
} from "./graphql/mutations";
import { GET_ORGANIZATIONS, GET_ORGANIZATION_BY_ID } from "./graphql/queries";
import { useOrgService } from "./hooks/useOrgService";
import * as RD from "src/types/remoteData";
import { identity } from "lodash";
import { GenericError } from "src/components/Feedback/GenericError";
import { useMemo, useState } from "react";
import { toGQLNoStudentAnnouncement } from "./services";
import { useLocation } from "react-router-dom";
import { z } from "zod";
import { AllOrgConfigs, DisabledOrgConfigs } from "./types";
import { useUpdateBranding } from "./components/BrandingForm";

export const Edit = () => {
  const { id = "" } = useParams();
  const toast = useToast();

  const { remoteData, updateOrgConfigs, orgPath } = useLoadData(id);
  const Branding = useUpdateBranding();
  const [updating, setUpdating] = useState(false);

  const [updateOrganization] = useRemoteDataMutation<
    GQL.UpdateOrganization,
    GQL.UpdateOrganizationVariables
  >(UPDATE_ORGANIZATION);

  const [getOrganizations] = useLazyRemoteDataQuery<GQL.GetOrganizations>(
    GET_ORGANIZATIONS,
    {
      fetchPolicy: "network-only",
    }
  );

  const [createAnnouncement] = useRemoteDataMutation<
    GQL.CreateAnnouncement,
    GQL.CreateAnnouncementVariables
  >(CREATE_ANNOUNCEMENT);

  const [updateAnnouncement] = useRemoteDataMutation<
    GQL.UpdateAnnouncement,
    GQL.UpdateAnnouncementVariables
  >(UPDATE_ANNOUNCEMENT);

  const navigate = useNavigate();

  const handleSubmit = async (values: OrganizationFormSchema) => {
    try {
      if (!orgPath.hasData()) {
        throw new Error("Missing organization path");
      }

      setUpdating(true);
      await updateOrganization({
        variables: {
          id,
          organization: {
            path: values.path,
            name: values.name,
            timezone_name: values.timezoneName,
          },
        },
      });

      const announcement = toGQLNoStudentAnnouncement(values, id);

      if (values.noStudentAnnouncement.id) {
        await updateAnnouncement({
          variables: {
            id: values.noStudentAnnouncement.id,
            announcement,
          },
        });
      } else if (
        values.noStudentAnnouncement.active &&
        values.noStudentAnnouncement.title &&
        values.noStudentAnnouncement.description
      ) {
        await createAnnouncement({
          variables: {
            announcement,
          },
        });
      }

      await Branding.updateBranding(values);

      await updateOrgConfigs(orgPath.data, values.organizationConfigs);
      await getOrganizations();

      toast({
        id,
        title: "Organization updated",
        isClosable: true,
        status: "success",
      });

      setUpdating(false);
      navigate(Url.Admin.Organizations.index());
    } catch (error) {
      setUpdating(false);
      console.error(error);
      const id = "EditOrganization";
      if (!toast.isActive(id))
        toast({
          id,
          title: "Error editing organization",
          description:
            "Please try again later or report the problem to our support team.",
          isClosable: true,
          status: "error",
        });
    }
  };

  return (
    <RemoteDataView
      remoteData={remoteData}
      error={() => <GenericError />}
      loading={<Loading />}
    >
      {({ organization, configs }) => {
        if (organization === null) {
          return <NotFound />;
        }
        return (
          <OrganizationForm
            organization={organization}
            configs={configs}
            onSubmit={handleSubmit}
            submitting={updating}
          />
        );
      }}
    </RemoteDataView>
  );
};

const Loading = () => {
  return (
    <Flex gap={8} direction="column" width="100%">
      <Skeleton height="3rem" width="15rem" />

      <Skeleton height="3rem" width="20rem" />
      <Skeleton height="10rem" width="100%" />

      <Skeleton height="3rem" width="20rem" />
      <Skeleton height="10rem" width="100%" />
    </Flex>
  );
};

type Organization = {
  id: string;
  name: string;
  path: string;
  timezone_name: string;
  announcements: GQL.GetOrganization_organization_by_pk_announcements[];
};

/**
 * This hook, useLoadData, determines its values based on the navigation state or from a backend service.
 * If the navigation state indicates a new organization, it uses the state to construct the organization data.
 * Otherwise, it fetches the organization data from the backend using a GraphQL query.
 * Similarly, for organization configurations, it defaults to disabled configurations for new organizations,
 * or fetches them from the backend service if the organization already exists.
 */
function useLoadData(id: string) {
  const location = useLocation();
  const navigationState = NavigationStateSchema.parse(location.state);

  const { remoteData: getOrganizationRD } = useRemoteDataQuery<
    GQL.GetOrganization,
    GQL.GetOrganizationVariables
  >(GET_ORGANIZATION_BY_ID, {
    variables: { id },
    skip: navigationState.isNewOrganization,
  });

  const organizationRD: RD.RemoteData<Error, Organization | null> =
    useMemo(() => {
      if (navigationState.isNewOrganization) {
        return RD.success<Error, Organization>({
          ...navigationState.organization,
          announcements: [],
        });
      }
      return getOrganizationRD.map((org) => org.organization_by_pk ?? null);
    }, [getOrganizationRD, navigationState]);

  const orgPath = organizationRD.andThen((org) =>
    org?.path
      ? RD.success<any, string>(org.path)
      : RD.failure<any, string>(new Error("Missing organization path"))
  );
  const { allOrgConfigs, updateOrgConfigs } = useOrgService({
    orgPath,
    skip: navigationState.isNewOrganization,
  });

  const configsRD = useMemo(() => {
    if (navigationState.isNewOrganization) {
      return RD.success<Error, AllOrgConfigs>({
        ...DisabledOrgConfigs,
        Auth0: {
          disabled: false,
          clientId: navigationState.organization.clientId,
        },
      });
    }
    return allOrgConfigs;
  }, [allOrgConfigs, navigationState]);

  const remoteData = RD.map2(
    organizationRD.mapError<Error>(identity),
    configsRD,
    (organization, configs) => ({
      organization,
      configs,
    })
  );

  return { remoteData, updateOrgConfigs, orgPath };
}

const NavigationStateSchema = z
  .discriminatedUnion("isNewOrganization", [
    z.object({
      isNewOrganization: z.literal(false),
    }),
    z.object({
      isNewOrganization: z.literal(true),
      organization: z.object({
        id: z.string(),
        name: z.string(),
        path: z.string(),
        timezone_name: z.string(),
        clientId: z.string(),
      }),
    }),
  ])
  .catch({ isNewOrganization: false });
