import { Input, InputProps } from "@chakra-ui/react";
import { useCallback, useState } from "react";
import { FieldInputProps, useField } from "formik";
import { debounce } from "lodash";
import { Flex } from "@chakra-ui/react";

/**
 * ColorInput is a dual-input component that provides both text and visual color selection functionality.
 *
 * The component consists of:
 * 1. A text input that accepts hex color values (e.g. "#FF0000")
 * 2. A color picker input that opens the browser's native color selector
 *
 * Features:
 * - Automatic # symbol prepending for hex values
 * - Native browser color picker integration
 * - 500ms debounced updates to prevent excess form submissions
 * - Validation for 6-digit hex colors
 * - Support for partial hex value entry
 * - Formik form integration
 * - Optional onChange callback for custom handling
 *
 * @param name - Field name used for Formik form integration
 * @param onChange - Optional callback that fires when the color value changes
 * @param textInputProps - Props to be spread onto the hex text input component
 * @param colorInputProps - Props to be spread onto the color picker input component
 */
export const ColorInput = ({
  name,
  textInputProps,
  colorInputProps,
  onChange,
}: Props) => {
  const [field, ,] = useField<string>(name);
  const [inputValue, setInputValue] = useState(field.value);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((value: string) => {
      field.onChange({
        target: {
          name: field.name,
          value,
        },
      });
      onChange?.(value, field);
    }, 500),
    [field.onChange, field.name]
  );

  const handleInputChange = (value: string) => {
    let newValue = value;
    if (value && !value.startsWith("#")) {
      newValue = `#${value}`;
    }

    if (!newValue || isPartialHexColor(newValue)) {
      setInputValue(newValue);
      debouncedOnChange(newValue);
    }
  };

  return (
    <Flex>
      <Input
        borderRightRadius="0"
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
        name={field.name}
        placeholder="#000000"
        maxLength={7}
        {...textInputProps}
      />
      <Input
        borderLeftRadius="0"
        borderLeft="none"
        width="40px"
        type="color"
        padding="1"
        value={inputValue}
        onChange={(e) => handleInputChange(e.target.value)}
        name={field.name}
        {...colorInputProps}
      />
    </Flex>
  );
};

type Props = {
  name: string;
  onChange?: (value: string, field: FieldInputProps<string>) => void;
  textInputProps?: InputProps;
  colorInputProps?: InputProps;
};

export const hexColorRegex = /^#([A-Fa-f0-9]{6})$/;
export const isValidHexColor = (color: string): boolean => {
  return hexColorRegex.test(color);
};

export const partialHexColorRegex = /^#[A-Fa-f0-9]{0,6}$/;
const isPartialHexColor = (color: string): boolean => {
  return partialHexColorRegex.test(color);
};
