import {
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useFormImportTemplateDownload } from "src/components/FormImport/useFormImportTemplateDownload";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { HasuraRole } from "src/types/hasuraRole";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { CreateFormDialog } from "./CreateFormDialog";
import { CreateFormsDialog } from "./CreateFormsDialog";
import React from "react";

interface CreateFormsButtonProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
}

export function CreateFormsButton({
  enrollmentPeriodId,
  formTemplateId,
  formTemplateName,
}: CreateFormsButtonProps) {
  const createFormDisclosure = useDisclosure();
  const createFormsDisclosure = useDisclosure();

  const { startDownload } = useFormImportTemplateDownload({
    formTemplateId,
  });

  return (
    <>
      <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
        <Menu>
          <MenuButton as={Button} variant="solid" colorScheme="primary">
            <Flex gap={2} lineHeight={4} padding={3}>
              <Text>Create</Text>
              <Icon as={ChevronDownIcon} />
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem onClick={createFormDisclosure.onOpen}>New form</MenuItem>
            <MenuItem onClick={createFormsDisclosure.onOpen}>
              New forms from file
            </MenuItem>
          </MenuList>
        </Menu>
      </WithRequiredHasuraRoles>

      <WithRequiredHasuraRoles roles={[HasuraRole.ORG_ADMIN]}>
        <Button
          variant="solid"
          colorScheme="primary"
          onClick={() => {
            createFormDisclosure.onOpen();
          }}
        >
          Create form
        </Button>
      </WithRequiredHasuraRoles>

      <CreateFormDialog
        enrollmentPeriodId={enrollmentPeriodId}
        formTemplateId={formTemplateId}
        isOpen={createFormDisclosure.isOpen}
        onClose={createFormDisclosure.onClose}
        enrollmentPeriodSelectInputProps={{
          isDisabled: true,
          isVisible: false,
        }}
      />

      <CreateFormsDialog
        enrollmentPeriodId={enrollmentPeriodId}
        formTemplateId={formTemplateId}
        formTemplateName={formTemplateName}
        isOpen={createFormsDisclosure.isOpen}
        onClose={createFormsDisclosure.onClose}
        onTemplateDownloadClick={startDownload}
      />
    </>
  );
}
