import { AllOrgConfigs, DisabledConfigSchema } from "../types";
import * as OrgConfig from "@avela/organization-config-sdk";
import { Flex } from "@chakra-ui/react";
import { TranslationOptionsConfigForm } from "./TranslationOptionsConfigForm";
import { ScheduleExportConfigForm } from "./ScheduleExportConfigForm";
import { MatchConfigForm } from "./MatchConfigForm";
import { z } from "zod";
import {
  createDisabledInitialConfig,
  getInitialValue,
} from "../services/orgConfig";

export const ConfigForm = () => {
  return (
    <Flex direction="column" gap={8}>
      <TranslationOptionsConfigForm />
      <ScheduleExportConfigForm />
      <MatchConfigForm />
    </Flex>
  );
};

export const FormikSchema = z.object({
  Match: z.union([OrgConfig.Match.ConfigSchema, DisabledConfigSchema]),
  ScheduleExport: z.union([
    OrgConfig.ScheduleExport.ConfigSchema,
    DisabledConfigSchema,
  ]),
  TranslationOptions: z.union([
    OrgConfig.TranslationOptions.ConfigSchema,
    DisabledConfigSchema,
  ]),
  Contacts: z.union([OrgConfig.Contacts.ConfigSchema, DisabledConfigSchema]),
});
export type FormikForm = z.infer<typeof FormikSchema>;

export function getInitialValues(
  configs: AllOrgConfigs | undefined
): FormikForm {
  if (!configs) {
    // No configs, this is a new org. Disable all configs.
    return {
      Match: createDisabledInitialConfig("Match"),
      ScheduleExport: createDisabledInitialConfig("ScheduleExport", {
        secretArn: "",
      }),
      TranslationOptions: createDisabledInitialConfig("TranslationOptions"),
      Contacts: createDisabledInitialConfig("Contacts"),
    };
  }

  return {
    Match: getInitialValue("Match", configs),
    ScheduleExport: getInitialValue("ScheduleExport", configs, {
      secretArn: "",
    }),
    TranslationOptions: getInitialValue("TranslationOptions", configs),
    Contacts: getInitialValue("Contacts", configs),
  };
}
