import { CheckIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Portal,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { RiArrowDownSLine, RiCalendarTodoLine } from "react-icons/ri";
import { MotionBox } from "src/animations/MotionBox";
import { useAnimationWithReduceMotion } from "src/hooks/useAnimation";
import * as GQL from "src/types/graphql";
import { GenericError } from "../Feedback/GenericError";
import { RemoteDataView } from "../Layout/RemoteDataView";
import { useEnrollmentPeriod } from "../Providers/EnrollmentPeriodProvider";
import { AnimationContext } from "./AnimationContext";

interface EnrollmentPeriodDropdownProps {
  organization: GQL.Organization;
}

export function NavEnrollmentPeriodDropdown({
  organization,
}: EnrollmentPeriodDropdownProps) {
  const animate = useAnimationWithReduceMotion();
  const {
    enrollmentPeriods,
    selectedEnrollmentPeriod,
    setSelectedEnrollmentPeriodId,
  } = useEnrollmentPeriod();

  return (
    <AnimationContext.Consumer>
      {({ mode, disableAnimation = true }) => {
        return (
          <MotionBox
            disableAnimation={disableAnimation}
            display="flex"
            as="li"
            margin="0"
            padding="0"
            justifyContent="center"
            alignSelf="stretch"
            layout
            {...animate(
              mode === "compact" ? { width: "2.25rem" } : { width: "100%" }
            )}
            transition={{ type: "inertia" }}
          >
            <RemoteDataView
              loading={<Skeleton width="16rem" height="2.5rem" />}
              remoteData={enrollmentPeriods}
              error={() => <GenericError />}
            >
              {(enrollmentPeriods) => (
                <Menu>
                  {mode === "compact" && (
                    <MenuButton
                      as={IconButton}
                      icon={<RiCalendarTodoLine />}
                      colorScheme="gray"
                      variant="outline"
                      backgroundColor="white"
                    ></MenuButton>
                  )}

                  {mode === "full" && (
                    <MenuButton
                      as={Button}
                      colorScheme="gray"
                      variant="outline"
                      backgroundColor="white"
                      width="100%"
                    >
                      <Flex>
                        <Flex
                          flex={1}
                          gap={2}
                          alignItems="center"
                          overflowX="hidden"
                        >
                          <RiCalendarTodoLine color="gray.700" />
                          <Text fontSize="sm">
                            {selectedEnrollmentPeriod?.name}
                          </Text>
                        </Flex>
                        <RiArrowDownSLine color="gray.700" />
                      </Flex>
                    </MenuButton>
                  )}

                  <Portal>
                    <MenuList
                      maxHeight="calc(100vh - 20em)"
                      overflowY="auto"
                      zIndex={10000}
                    >
                      {enrollmentPeriods.map((enrollmentPeriod) => {
                        const isSelected =
                          enrollmentPeriod.id === selectedEnrollmentPeriod?.id;
                        return (
                          <MenuItem
                            icon={isSelected ? <CheckIcon /> : <></>}
                            value={enrollmentPeriod.id}
                            key={enrollmentPeriod.id}
                            onClick={() => {
                              setSelectedEnrollmentPeriodId(
                                enrollmentPeriod.id
                              );
                            }}
                          >
                            {enrollmentPeriod.name}
                          </MenuItem>
                        );
                      })}
                    </MenuList>
                  </Portal>
                </Menu>
              )}
            </RemoteDataView>
          </MotionBox>
        );
      }}
    </AnimationContext.Consumer>
  );
}
