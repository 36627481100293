import * as Env from "src/services/env";
import { useOrgConfig } from "src/hooks/useOrgConfig";
import * as RD from "src/types/remoteData";
import { useOrganizationPath } from "./useOrganizationPath";

type Auth0ClientId =
  | { type: "organization"; clientId: string }
  | { type: "default"; clientId: string };
export function useAuth0ClientId(): RD.RemoteData<never, Auth0ClientId> {
  const env = Env.read();
  const orgConfig = useOrgConfig("Auth0");
  const orgPath = useOrganizationPath();

  if (orgPath === undefined || ["admin", "auth", "logout"].includes(orgPath))
    return RD.success<never, Auth0ClientId>({
      type: "default",
      clientId: env.REACT_APP_AUTH0_CLIENT_ID,
    });

  return orgConfig
    .andThen<Auth0ClientId>((config) => {
      if (!config.disabled) {
        return RD.success({
          type: "organization",
          clientId: config.clientId,
        });
      }

      return RD.failure(
        new Error(
          `Missing clientId for ${orgPath}, most likely due to incomplete organization creation.`
        )
      );
    })
    .recover<never>((error) => {
      // IMPORTANT: Use Auth0's default clientId on any error to allow user to keep using the system.
      // The only known downside of using default clientId is that the user will recieve unbranded email when using password reset or resend email verification.
      console.error(error);
      return RD.success({
        type: "default",
        clientId: env.REACT_APP_AUTH0_CLIENT_ID,
      });
    });
}
