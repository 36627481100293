import { Button, Flex, Heading, Spacer } from "@chakra-ui/react";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import * as Url from "src/services/url";
import { BasicInformationForm } from "./BasicInformationForm";
import { Form, Formik } from "formik";
import { z } from "zod";
import { AdminFormButtons } from "src/components/Layout/AdminFormButtons";
import { validateWithZod } from "src/services/formValidations";
import { useCreateOrganization } from "./hooks/useOrgService";
import { ReactComponent as LaunchAppSvg } from "src/images/launch-app.svg";
import { ReactComponent as ConnectSvg } from "src/images/connect.svg";
import { EmptyState } from "src/components/EmptyState";
import * as RD from "src/types/remoteData";
import { RemoteDataView } from "src/components/Layout/RemoteDataView";

export const NewOrganizationFormikSchema = z.object({
  name: z.string().min(1, "This field is required"),
  path: z.string().min(1, "This field is required"),
  timezoneName: z.string().min(1, "This field is required"),
});
type NewOrganizationFormik = z.infer<typeof NewOrganizationFormikSchema>;

export const New = () => {
  const navigate = useNavigate();
  const [creationState, setCreationState] = useState<
    RD.RemoteData<unknown, void>
  >(RD.notAsked());
  const createOrganization = useCreateOrganization();

  const handleSubmit = React.useCallback(
    async (values: NewOrganizationFormik) => {
      try {
        setCreationState(RD.loading());
        const { organizationId, clientId } = await createOrganization(values);
        setCreationState(RD.success(undefined));
        navigate(Url.Admin.Organizations.edit(organizationId), {
          state: {
            isNewOrganization: true,
            organization: {
              id: organizationId,
              name: values.name,
              path: values.path,
              timezone_name: values.timezoneName,
              clientId,
            },
          },
        });
      } catch (error) {
        setCreationState(RD.failure(error));
        console.error(error);
      }
    },
    [createOrganization, navigate]
  );

  return (
    <Formik<NewOrganizationFormik>
      initialValues={{ name: "", path: "", timezoneName: "" }}
      onSubmit={handleSubmit}
      validate={validateWithZod(NewOrganizationFormikSchema)}
    >
      {() => (
        <Flex direction="column" gap={6} as={Form} minHeight="100%" noValidate>
          <Heading as="h1" size="xl" fontWeight="semibold">
            Create new organization
          </Heading>
          <BasicInformationForm />
          <Flex justifyContent="center">
            <RemoteDataView
              remoteData={creationState}
              loading={
                <EmptyState
                  alignSelf="center"
                  description="Additional settings will be available once we're done"
                  heading="Marinating some details"
                  Svg={LaunchAppSvg}
                />
              }
              error={(error) => (
                <EmptyState
                  alignSelf="center"
                  description={(error as any).message ?? "Unknown error"}
                  heading="Something went wrong"
                  Svg={ConnectSvg}
                />
              )}
            >
              {() => null}
            </RemoteDataView>
          </Flex>
          <Spacer />
          <AdminFormButtons sticky>
            <Button
              variant="outline"
              colorScheme="gray"
              onClick={() => navigate(Url.Admin.Organizations.index())}
              isDisabled={creationState.isLoading()}
            >
              Back
            </Button>
            <Spacer />
            <Button type="submit" isLoading={creationState.isLoading()}>
              Create org
            </Button>
          </AdminFormButtons>
        </Flex>
      )}
    </Formik>
  );
};
