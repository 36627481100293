import {
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Select,
} from "@chakra-ui/react";
import { Field, useFormikContext } from "formik";
import { InputControl } from "formik-chakra-ui";
import React from "react";

type BasicInformationFormik = {
  name: string;
  path: string;
  timezoneName: string;
};

type Props = {
  isNew?: boolean;
};

export function BasicInformationForm({ isNew = true }: Props) {
  const formik = useFormikContext<BasicInformationFormik>();
  const timezoneNames = React.useMemo(() => {
    return (Intl as any).supportedValuesOf("timeZone");
  }, []);

  return (
    <>
      <Heading as="h2" variant="admin">
        Basic information
      </Heading>
      <Flex direction="column">
        <InputControl
          name="name"
          label="Organization name"
          isRequired
          isDisabled={!isNew}
        >
          <Input size="sm" placeholder="Organization name" />
        </InputControl>
      </Flex>
      <Flex direction="column">
        <InputControl name="path" label="Path" isRequired isDisabled={!isNew}>
          <Input size="sm" placeholder="Path" />
        </InputControl>
      </Flex>
      <Flex direction="column">
        <FormControl
          isRequired
          isInvalid={formik.errors.timezoneName !== undefined}
        >
          <FormLabel htmlFor="timezoneName">Timezone name</FormLabel>
          <Field
            as={Select}
            id="timezoneName"
            name="timezoneName"
            placeholder="Select timezone"
          >
            {timezoneNames.map((value: string) => (
              <option value={value} key={value}>
                {value}
              </option>
            ))}
          </Field>
          <FormErrorMessage>{formik.errors.timezoneName}</FormErrorMessage>
        </FormControl>
      </Flex>
    </>
  );
}
