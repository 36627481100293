import { ApolloError as ApolloErrorType } from "@apollo/client";
import React from "react";
import { RemoteData } from "src/types/remoteData";
import { ApolloError } from "../Feedback/ApolloError";
import { Loading } from "../Feedback/Loading";
import { RemoteDataContent } from "./RemoteData/RemoteDataContent";
import { RemoteDataLoadingIndicator } from "./RemoteData/RemoteDataLoadingIndicator";

type Config = {
  showDataWhileReloading?: boolean;
};
const DEFAULT_CONFIG = {
  showDataWhileReloading: true,
};
type Props<E, T> = {
  remoteData: RemoteData<E, T>;
  loading?: React.ReactElement | null;
  reloading?: React.ReactElement | null;
  error: (e: E) => React.ReactElement | null;
  notAsked?: React.ReactElement | null;
  children: (data: T) => React.ReactElement[] | React.ReactElement | null;
  config?: Config;
};

/** Helper component for rendering remote data */
export function RemoteDataView<E, T>({
  remoteData,
  loading,
  reloading,
  error,
  notAsked,
  children,
  config,
}: Props<E, T>): React.ReactElement | null {
  const { showDataWhileReloading } = config ?? DEFAULT_CONFIG;

  return (
    <>
      {remoteData.isNotAsked() && notAsked}

      <RemoteDataLoadingIndicator
        loadingElement={loading}
        reloadingElement={reloading}
        remoteData={remoteData}
      />

      {remoteData.hasError() && error(remoteData.error)}

      <RemoteDataContent
        children={children}
        hideWhileReloading={!showDataWhileReloading}
        remoteData={remoteData}
      />
    </>
  );
}

type GQLProps<ApolloError, T> = {
  remoteData: RemoteData<ApolloError, T>;
  children: (data: T) => React.ReactElement | null;
  loading?: React.ReactElement;
  error?: (error: ApolloError) => React.ReactElement | null;
} & Omit<
  Props<ApolloError, T>,
  "error" | "loading" | "children" | "remoteData"
>;

/**
 * Helper component for rendering remote data specific for GraphQL data
 */
export function GQLRemoteDataView<T>({
  remoteData,
  children,
  loading = <Loading />,
  error,
  ...props
}: GQLProps<ApolloErrorType, T>): React.ReactElement | null {
  const apolloError = React.useCallback(
    (error: ApolloErrorType) => <ApolloError error={error} />,
    []
  );

  return RemoteDataView({
    remoteData,
    children,
    error: error ?? apolloError,
    loading,
    ...props,
  });
}
