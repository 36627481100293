import { ExternalLinkIcon } from "@chakra-ui/icons";
import { Image, useDisclosure } from "@chakra-ui/react";
import React from "react";
import {
  RiAppsLine,
  RiChat2Line,
  RiClipboardLine,
  RiContactsLine,
  RiDashboard2Line,
  RiHome5Line,
  RiQuestionLine,
  RiTeamLine,
  RiUserLine,
  RiUserSettingsLine,
} from "react-icons/ri";
import { useLocation } from "react-router-dom";
import { useFormImportFlow } from "src/components/FormImport/FormImportFlowContext";
import { FlowStatus } from "src/components/FormImport/state/constants";
import { BaseLayout } from "src/components/Layout/BaseLayout";
import { Header } from "src/components/Layout/Header";
import { LeftNav, NavGroup, NavLinkItem } from "src/components/Navigation";
import { NavEnrollmentPeriodDropdown } from "src/components/Navigation/NavEnrollmentPeriodDropdown";
import { NavFormsList } from "src/components/Navigation/NavFormsList";
import { NavigationFoldButton } from "src/components/Navigation/NavigationFoldButton";
import { WithConfig } from "src/components/Permissions/WithConfig";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { useBranding } from "src/components/Providers/BrandingProvider";
import { useOrganization } from "src/hooks/useOrganization";
import { getLogoAlt } from "src/schemas/Branding";
import * as Url from "src/services/url";
import { Organization } from "src/types/graphql";

export const Layout: React.FC = () => {
  const branding = useBranding();
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const location = useLocation();
  const organization = useOrganization();

  const logo = (
    <>
      <NavigationFoldButton isOpen={isOpen} onToggle={onToggle} />
      {branding && (
        <Image
          src={branding.logos.whiteSvg}
          alt={getLogoAlt(branding)}
          height="3em"
          maxWidth="9rem"
        />
      )}
    </>
  );

  const {
    state: {
      formImportId,
      formTemplateId,
      formTemplateName,
      importFileUploadStatus,
    },
  } = useFormImportFlow();

  const OrgAdminNav = (org: Organization) => {
    let transientLinks: JSX.Element | null = null;

    if (
      formImportId &&
      formTemplateId &&
      location.pathname !== Url.OrgAdmin.FormImport.view(org, formTemplateId)
    ) {
      transientLinks = (
        <NavGroup>
          <NavLinkItem
            label={`Creating forms ${
              formTemplateName ? `for ${formTemplateName}` : ""
            }`}
            leadIcon={undefined}
            showSpinner={importFileUploadStatus === FlowStatus.STARTED}
            url={Url.OrgAdmin.FormImport.view(org, formTemplateId)}
          />
        </NavGroup>
      );
    }

    return (
      <LeftNav isOpen={isOpen}>
        <WithUserPermissions permissions={["navigation.admin.forms"]}>
          {transientLinks}
        </WithUserPermissions>

        <NavGroup label="Enrollment">
          <NavEnrollmentPeriodDropdown organization={org} />
          <WithUserPermissions permissions={["navigation.admin.forms"]}>
            <NavFormsList organization={org} />
          </WithUserPermissions>
          <WithUserPermissions permissions={["navigation.admin.capacities"]}>
            <NavLinkItem
              url={Url.OrgAdmin.CapacitiesConfig.index(org)}
              label="Capacities"
              leadIcon={RiDashboard2Line}
            />
          </WithUserPermissions>
        </NavGroup>
        <WithUserPermissions
          permissions={[
            "navigation.admin.enrollment_periods",
            "navigation.admin.schools",
            "navigation.admin.users",
            "navigation.admin.team",
            "navigation.admin.messages",
          ]}
        >
          <NavGroup label="General">
            <WithUserPermissions
              permissions={["navigation.admin.enrollment_periods"]}
            >
              <NavLinkItem
                leadIcon={RiClipboardLine}
                label="Enrollment periods"
                url={Url.OrgAdmin.Enrollments.index(org)}
              />
            </WithUserPermissions>
            <WithConfig configType="Match">
              <WithUserPermissions
                permissions={["navigation.admin.priority_templates"]}
              >
                <NavLinkItem
                  url={Url.OrgAdmin.MatchTemplates.index(org)}
                  label="Match templates"
                  leadIcon={RiContactsLine}
                />
              </WithUserPermissions>
            </WithConfig>
            <WithUserPermissions permissions={["navigation.admin.schools"]}>
              <NavLinkItem
                leadIcon={RiHome5Line}
                label="Schools"
                url={Url.OrgAdmin.Schools.index(org)}
              />
            </WithUserPermissions>
            <WithUserPermissions permissions={["navigation.admin.users"]}>
              <NavLinkItem
                leadIcon={RiUserLine}
                label="Students"
                url={Url.OrgAdmin.Students.index(org)}
              />
              <NavLinkItem
                leadIcon={RiTeamLine}
                label="Parents"
                url={Url.OrgAdmin.Parents.index(org)}
              />
            </WithUserPermissions>
            <WithUserPermissions permissions={["navigation.admin.team"]}>
              <NavLinkItem
                leadIcon={RiUserSettingsLine}
                label="Teams & members"
                url={Url.OrgAdmin.Team.index(org)}
              />
            </WithUserPermissions>
            <WithUserPermissions permissions={["navigation.admin.messages"]}>
              <NavLinkItem
                leadIcon={RiChat2Line}
                label="Messages"
                url={Url.OrgAdmin.Messages.index(org)}
              />
            </WithUserPermissions>
            <WithUserPermissions
              permissions={["navigation.admin.data_services"]}
            >
              <NavLinkItem
                leadIcon={RiAppsLine}
                label="Data services"
                url={Url.OrgAdmin.DataServices.index(org)}
              />
            </WithUserPermissions>
          </NavGroup>
        </WithUserPermissions>
        <NavGroup label="Help">
          <NavLinkItem
            leadIcon={RiQuestionLine}
            trailIcon={ExternalLinkIcon}
            trailIconVisibility="full"
            isExternal
            label="Avela support"
            url="https://avelahelp.zendesk.com/hc/en-us"
          />
        </NavGroup>
      </LeftNav>
    );
  };

  return (
    <BaseLayout
      header={<Header logo={logo} />}
      leftNav={organization
        .map<React.ReactNode>((org: Organization) => {
          return OrgAdminNav(org);
        })
        .withDefault(null)}
    />
  );
};
