import {
  Button,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Form, Formik, FormikHelpers } from "formik";
import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { InfoAlert } from "src/components/Feedback/InfoAlert";
import { ApplicantSelectInput } from "src/components/Inputs/ApplicantSelectInput";
import { EnrollmentPeriodSelectInput } from "src/components/Inputs/EnrollmentPeriodSelectInput";
import { FormTemplateSelectInput } from "src/components/Inputs/FormTemplateSelectInput";
import { useOrganization } from "src/hooks/useOrganization";
import { validateWithZod } from "src/services/formValidations";
import { Forms } from "src/services/url/OrgAdmin";
import * as GQL from "src/types/graphql";
import { z } from "zod";
import { GET_ACTIVE_FORMS_BY_STUDENT_ID } from "../../students/graphql/queries";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import { useRemoteDataMutation } from "src/hooks/useRemoteDataMutation";
import { CREATE_FORM } from "src/components/graphql/mutations";
import { GET_FORMS_BY_FORM_TEMPLATE } from "../graphql/queries";
import { useAvelaToast } from "src/hooks/useAvelaToast";

const FormSchema = z.object({
  organizationId: z.string().uuid("Organization is required"),
  enrollmentPeriodId: z.string().uuid("Enrollment period is required"),
  formTemplateId: z.string().uuid("Form is required"),
  applicantId: z.string().uuid("Student is required"),
});

type FormType = z.infer<typeof FormSchema>;

interface CreateFormDialogProps {
  enrollmentPeriodId?: uuid;
  formTemplateId?: uuid;
  applicantId?: uuid;
  isOpen: boolean;
  onClose: () => void;
  width?: string;
  height?: string;
  enrollmentPeriodSelectInputProps?: {
    isDisabled?: boolean;
    isVisible?: boolean;
  };
  applicantSelectInputProps?: {
    isDisabled?: boolean;
  };
}

export function CreateFormDialog({
  enrollmentPeriodId,
  formTemplateId,
  applicantId,
  isOpen,
  onClose,
  width,
  height,
  applicantSelectInputProps = {},
  enrollmentPeriodSelectInputProps = {},
}: CreateFormDialogProps) {
  const organization = useOrganization();
  const initialValues: FormType = React.useMemo(
    () => ({
      organizationId: organization.map((org) => org.id).withDefault(""),
      enrollmentPeriodId: enrollmentPeriodId || "",
      formTemplateId: formTemplateId || "",
      applicantId: applicantId || "",
    }),
    [organization, enrollmentPeriodId, formTemplateId, applicantId]
  );

  const [searchParams] = useSearchParams();
  const organizationId = React.useMemo(
    () => organization.map((org) => org.id).withDefault(""),
    [organization]
  );
  const navigate = useNavigate();
  const toast = useAvelaToast();

  const [selectedFormTemplate, setSelectedFormTemplate] = React.useState<
    GQL.GetBasicFormTemplatesByEnrollmentPeriod_form_template | null | undefined
  >();
  const [selectedApplicant, setSelectedApplicant] = React.useState<
    GQL.GetStudents_person | null | undefined
  >();

  const [createForm] = useRemoteDataMutation<
    GQL.CreateForm,
    GQL.CreateFormVariables
  >(CREATE_FORM);

  const { remoteData: remoteActiveForms } = useRemoteDataQuery<
    GQL.GetActiveFormsByStudentId,
    GQL.GetActiveFormsByStudentIdVariables
  >(GET_ACTIVE_FORMS_BY_STUDENT_ID, {
    variables: {
      id: selectedApplicant?.id || "",
    },
    skip: !selectedApplicant,
    fetchPolicy: "network-only",
  });

  const onSelectedFormTemplate = (
    formTemplate:
      | GQL.GetBasicFormTemplatesByEnrollmentPeriod_form_template
      | null
      | undefined
  ) => {
    setSelectedFormTemplate(formTemplate);
  };

  const onSelectedApplicant = (
    applicant: GQL.GetStudents_person | null | undefined
  ) => {
    setSelectedApplicant(applicant);
    if (!applicant) setActiveFormsLookup(new Map());
  };

  const [activeFormsLookup, setActiveFormsLookup] = React.useState<
    Map<uuid, uuid>
  >(new Map());

  React.useEffect(() => {
    if (!remoteActiveForms.hasError() && remoteActiveForms.hasData()) {
      const lookup = new Map<uuid, uuid>();
      remoteActiveForms.data.form.forEach(({ id, form_template_id }) =>
        lookup.set(form_template_id, id)
      );
      setActiveFormsLookup(lookup);
    }
  }, [remoteActiveForms]);

  const [alertText, setAlertText] = React.useState<string | null>(null);

  React.useEffect(() => {
    if (
      !selectedApplicant ||
      !selectedFormTemplate ||
      !activeFormsLookup.has(selectedFormTemplate.id)
    ) {
      setAlertText(null);
    } else {
      const vowels = ["a", "e", "i", "o", "u"];
      const formArticle = vowels.includes(
        selectedFormTemplate.name[0]?.toLocaleLowerCase() ?? ""
      )
        ? "an"
        : "a";

      const text = `${selectedApplicant?.first_name} ${selectedApplicant?.last_name} already has ${formArticle} ${selectedFormTemplate.name} form.`;

      setAlertText(text);
    }
  }, [activeFormsLookup, selectedApplicant, selectedFormTemplate]);

  const handleClose = React.useCallback(
    (resetForm: () => void) => {
      resetForm();
      setSelectedFormTemplate(null);
      setSelectedApplicant(null);
      setActiveFormsLookup(new Map());
      setAlertText(null);
      onClose();
    },
    [onClose]
  );

  const handleSubmitForm = React.useCallback(
    (values: FormType, { resetForm }: FormikHelpers<FormType>) => {
      const variables: GQL.CreateFormVariables = {
        person_id: values.applicantId,
        form_template_id: values.formTemplateId,
      };

      createForm({
        variables,
        refetchQueries: [
          GET_ACTIVE_FORMS_BY_STUDENT_ID,
          GET_FORMS_BY_FORM_TEMPLATE,
        ],
      })
        .then((result) => {
          handleClose(resetForm);

          toast({
            title: "Form created",
            status: "success",
            isClosable: true,
          });

          setTimeout(() => {
            navigate(
              organization
                .map(() =>
                  Forms.view({
                    organization,
                    id: result.data?.insert_form_one?.id || "",
                    formTemplateId: values.formTemplateId,
                    params: searchParams.toString(),
                  })
                )
                .withDefault("#")
            );
          }, 100);
        })
        .catch((error) => {
          console.error(error);
          toast({
            title: "Could not create form",
            status: "error",
            isClosable: true,
          });
        });
    },
    [handleClose, createForm, navigate, organization, searchParams, toast]
  );

  const handleOnSeeFormClick = (
    formTemplateId: uuid,
    handleClose: () => void
  ) => {
    handleClose();

    // This is a workaround to fix the issue where the modal is not closed before the form is opened, which will
    //disable scrolling on the form detail page.
    setTimeout(() => {
      navigate(
        Forms.view({
          organization,
          id: activeFormsLookup?.get(formTemplateId) || "",
          formTemplateId: formTemplateId,
          params: searchParams.toString(),
        })
      );
    }, 100);
  };

  return (
    <Formik<FormType>
      initialValues={initialValues}
      enableReinitialize
      onSubmit={handleSubmitForm}
      validate={validateWithZod(FormSchema)}
      validateOnBlur={true}
      validateOnMount={true}
      validateOnChange={true}
    >
      {({ values, isValid, resetForm }) => {
        const handleOnClose = () => handleClose(resetForm);
        return (
          <Modal isCentered isOpen={isOpen} onClose={handleOnClose}>
            <ModalOverlay />
            <ModalContent width={width} height={height}>
              <Form>
                <ModalHeader>Create new form</ModalHeader>

                <ModalBody as={VStack} padding={8}>
                  <Flex direction="column" gap={4} width="100%">
                    <EnrollmentPeriodSelectInput
                      name="enrollmentPeriodId"
                      organizationId={organizationId}
                      isRequired={true}
                      {...enrollmentPeriodSelectInputProps}
                    />
                    <FormTemplateSelectInput
                      name="formTemplateId"
                      enrollmentPeriodId={values.enrollmentPeriodId}
                      onSelectedFormTemplate={onSelectedFormTemplate}
                      isRequired={true}
                    />
                    <ApplicantSelectInput
                      name="applicantId"
                      organizationId={organizationId}
                      onSelectedApplicant={onSelectedApplicant}
                      isRequired={true}
                      {...applicantSelectInputProps}
                    />

                    {alertText && (
                      <InfoAlert>
                        <Text fontSize="md">
                          {alertText}
                          <Link
                            variant="link"
                            onClick={() =>
                              handleOnSeeFormClick(
                                values.formTemplateId,
                                handleOnClose
                              )
                            }
                            color="blue.900"
                            fontWeight="700"
                            fontSize="md"
                            textDecoration="underline"
                            paddingLeft="2"
                          >
                            See form.
                          </Link>
                        </Text>
                      </InfoAlert>
                    )}
                  </Flex>
                </ModalBody>

                <ModalFooter gap={2}>
                  <Button onClick={handleOnClose} variant="ghost">
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    isDisabled={alertText ? true : !isValid}
                  >
                    Create
                  </Button>
                </ModalFooter>
              </Form>
            </ModalContent>
          </Modal>
        );
      }}
    </Formik>
  );
}
