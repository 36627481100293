import { uniq } from "lodash";
import { useCallback, useMemo } from "react";
import { AttendanceTypes } from "src/constants";
import * as GQL from "src/types/graphql";
import { HasuraRole } from "src/types/hasuraRole";
import { useSchoolAdmin } from "./useSchoolAdmin";

type UseSchoolAdminPermissions = {
  schoolFilter(): GQL.school_bool_exp;
  formSchoolRankFilter(): GQL.form_school_rank_bool_exp;
  messageAdhocFilter(): GQL.message_adhoc_bool_exp;
  messageAdhocReceiverFilter(): GQL.message_adhoc_receiver_bool_exp;

  hasSectionAccess(section: GQL.FormTemplateSectionFragment): boolean;
  hasQuestionAccess(
    question: GQL.FormQuestionWithoutBranchingFragment,
    attendanceContext: AttendanceTypes | string | null
  ): boolean;
};

export function useSchoolAdminPermissions(): UseSchoolAdminPermissions {
  const { isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds } =
    useSchoolAdmin();
  const allSchools = useMemo(
    () => uniq([...applyingSchoolIds, ...attendingSchoolIds]),
    [applyingSchoolIds, attendingSchoolIds]
  );

  const schoolFilter = useCallback(
    () => (isElevatedSchoolAdmin ? createSchoolFilter(allSchools) : {}),
    [isElevatedSchoolAdmin, allSchools]
  );
  const formSchoolRankFilter = useCallback(
    () =>
      isElevatedSchoolAdmin
        ? createFormSchoolRankFilter(applyingSchoolIds, attendingSchoolIds)
        : {},
    [isElevatedSchoolAdmin, applyingSchoolIds, attendingSchoolIds]
  );

  const messageAdhocFilter = useCallback(
    () => (isElevatedSchoolAdmin ? createMessageAdhocFilter(allSchools) : {}),
    [isElevatedSchoolAdmin, allSchools]
  );
  const messageAdhocReceiverFilter = useCallback(
    () =>
      isElevatedSchoolAdmin ? createMessageAdhocReceiverFilter(allSchools) : {},
    [isElevatedSchoolAdmin, allSchools]
  );

  const hasSectionAccess = hasPermissionLevelAccess;
  const hasQuestionAccess = useCallback(
    (
      q: GQL.FormQuestionWithoutBranchingFragment,
      attendanceContext: string | null
    ) =>
      hasPermissionLevelAccess(q) &&
      (attendanceContext === AttendanceTypes.Attending ||
        !q.form_question_schools.length ||
        !!q.form_question_schools.find((fqs) =>
          applyingSchoolIds.includes(fqs.school_id)
        )),
    [applyingSchoolIds]
  );

  return {
    schoolFilter,
    formSchoolRankFilter,
    messageAdhocFilter,
    messageAdhocReceiverFilter,
    hasSectionAccess,
    hasQuestionAccess,
  };
}

const hasPermissionLevelAccess = (obj: { permission_level: string | null }) =>
  !obj.permission_level || obj.permission_level === HasuraRole.SCHOOL_ADMIN;

const createSchoolFilter = (allSchools: uuid[]): GQL.school_bool_exp => ({
  id: {
    _in: allSchools,
  },
});

const createFormSchoolRankFilter = (
  applyingSchoolIds: uuid[],
  attendingSchoolIds: uuid[]
): GQL.form_school_rank_bool_exp => ({
  _or: [
    { school_id: { _in: applyingSchoolIds } },
    {
      form: {
        form_attending_school: {
          school_id: { _in: attendingSchoolIds },
        },
      },
    },
  ],
});

const createMessageAdhocFilter = (
  allSchools: uuid[]
): GQL.message_adhoc_bool_exp => ({
  // This checks that the current admin at least partially overlaps in schools
  // with the message sender, which doesn't exactly capture the desired behavior
  // (see https://app.asana.com/0/1200299036901049/1207960074974025/f), but
  // matches the backend permissions for regular school admins.
  sender_person: {
    user: {
      school_users: {
        school_id: {
          _in: allSchools,
        },
      },
    },
  },
});

const createMessageAdhocReceiverFilter = (
  allSchools: uuid[]
): GQL.message_adhoc_receiver_bool_exp => ({
  receiver_user_schools: {
    school_id: {
      _in: allSchools,
    },
  },
});
