import { Box, BoxProps, Button, InputGroup, Text } from "@chakra-ui/react";
import { InputHTMLAttributes, useRef } from "react";
import { RiUploadCloud2Line } from "react-icons/ri";

export type Props = {
  buttonLabel?: string;
  label?: string;
  disabled?: boolean;
  accept?: InputHTMLAttributes<HTMLInputElement>["accept"];
  boxProps?: BoxProps;
};

type SingleFileProps = {
  multiple?: false;
  onUpload: (data: File) => void;
} & Props;

type MultipleFileProps = {
  multiple: true;
  onUpload: (data: FileList) => void;
} & Props;

export type UploadFileInputProps = SingleFileProps | MultipleFileProps;

function isMultipleFileProps(
  props: UploadFileInputProps
): props is MultipleFileProps {
  return props.multiple === true;
}

export const UploadFileInput: React.FC<UploadFileInputProps> = (props) => {
  const {
    multiple = false,
    buttonLabel = "Upload file",
    label,
    disabled = false,
    accept = "image/png, image/jpg, image/jpeg, application/pdf, .heic, .heif",
    boxProps,
    ...rest
  } = props;
  const fileRef = useRef<HTMLInputElement>(null);

  const handleChangeUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (isMultipleFileProps(props)) {
      const files = event.currentTarget?.files
        ? event.currentTarget.files
        : undefined;

      if (files === undefined) return;

      props.onUpload(files);
    } else {
      const file = event.currentTarget?.files?.[0];
      if (file === undefined) return;
      props.onUpload(file);
    }

    // clear the file to allow re-upload of same file
    event.target.value = "";
  };

  return (
    <Box my={2} {...boxProps}>
      {label && <Text mb="10px">{label}</Text>}
      <InputGroup flexBasis="fit-content">
        <input
          type="file"
          accept={accept}
          ref={fileRef}
          style={{ display: "none" }}
          onChange={handleChangeUpload}
          disabled={disabled}
          multiple={multiple}
          {...rest}
        ></input>
        <Button
          leftIcon={<RiUploadCloud2Line />}
          onClick={() => fileRef.current?.click()}
          variant="outline"
          colorScheme="gray"
          width="100%"
          isDisabled={disabled}
        >
          {buttonLabel}
        </Button>
      </InputGroup>
    </Box>
  );
};
