import { gql } from "@apollo/client";

export const GET_MESSAGE_TEMPLATE_TYPES = gql`
  query GetMessageTemplateTypes($formTemplateId: uuid!) {
    message_template_type(order_by: { value: asc }) {
      value
    }
    message_template(where: { form_template_id: { _eq: $formTemplateId } }) {
      id
      type
      enabled
    }
    form_template_by_pk(id: $formTemplateId) {
      id
      lottery_offers_enabled
      sections(where: { type: { _eq: SchoolRankingSection } }) {
        schools_ranking_section {
          ranking_enabled
        }
      }
    }
  }
`;

export const GET_MESSAGE_TEMPLATE = gql`
  query GetMessageTemplate(
    $formTemplateId: uuid!
    $templateType: message_template_type_enum!
  ) {
    message_template(
      where: {
        _and: [
          { form_template_id: { _eq: $formTemplateId } }
          { type: { _eq: $templateType } }
        ]
      }
    ) {
      id
      type
      email_subject
      email_markup
      email_plain_text
      sms_body
      enabled
    }
    form_template_by_pk(id: $formTemplateId) {
      sections(where: { type: { _eq: SchoolRankingSection } }) {
        schools_ranking_section {
          ranking_enabled
        }
      }
    }
  }
`;
