import * as OrgConfig from "@avela/organization-config-sdk";

export type Branding = OrgConfig.Branding.Config;
export const DefaultBranding = OrgConfig.Branding.DefaultConfig;

export function getWhiteLogo(branding: Branding): {
  url: string;
  alt: string;
} {
  return {
    url: branding.logos.whiteSvg,
    alt: getLogoAlt(branding),
  };
}

export function getColorLogo(branding: Branding): {
  url: string;
  alt: string;
} {
  return {
    url: branding.logos.colorSvg,
    alt: getLogoAlt(branding),
  };
}

export function getLogoAlt(branding: Branding) {
  return `${branding.name} logo`;
}
