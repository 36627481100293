import { SelectControl } from "formik-chakra-ui";
import React from "react";
import { useRemoteDataQuery } from "src/hooks/useRemoteDataQuery";
import * as GQL from "src/types/graphql";
import { GET_BASIC_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD } from "../graphql/queries";
import { useField } from "formik";

interface FormTemplateSelectInputProps {
  name: string;
  enrollmentPeriodId: uuid;
  label?: string;
  placeholder?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  onSelectedFormTemplate?: (
    selected:
      | GQL.GetBasicFormTemplatesByEnrollmentPeriod_form_template
      | null
      | undefined
  ) => void;
}

export const FormTemplateSelectInput: React.FC<
  FormTemplateSelectInputProps
> = ({
  name,
  enrollmentPeriodId,
  label = "Form",
  placeholder = "Select a form",
  isRequired = false,
  isDisabled = false,
  onSelectedFormTemplate,
}) => {
  const [hasUserSelected, setHasUserSelected] = React.useState(false);
  const [field, { initialValue }, helper] = useField(name);
  const { remoteData } = useRemoteDataQuery<
    GQL.GetBasicFormTemplatesByEnrollmentPeriod,
    GQL.GetBasicFormTemplatesByEnrollmentPeriodVariables
  >(GET_BASIC_FORM_TEMPLATES_BY_ENROLLMENT_PERIOD, {
    variables: {
      enrollment_period: enrollmentPeriodId,
      search: {
        _not: { status_rules: {} },
      },
    },
    skip: !enrollmentPeriodId,
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (!data.form_template.find((template) => template.id === field.value)) {
        helper.setValue("");
        onSelectedFormTemplate?.(null);
      }
    },
  });

  const templates = React.useMemo(
    () =>
      !remoteData.hasError() && remoteData.hasData()
        ? remoteData.data.form_template
        : [],
    [remoteData]
  );

  React.useEffect(() => {
    if (remoteData.hasError()) {
      helper.setError("Error loading forms");
      onSelectedFormTemplate?.(null);
    }
  }, [remoteData, helper, onSelectedFormTemplate]);

  React.useEffect(() => {
    if (!hasUserSelected && templates.length > 0) {
      const preSelectedTemplate = templates.find(
        (template) => template.id === initialValue
      );
      if (preSelectedTemplate) {
        onSelectedFormTemplate?.(preSelectedTemplate);
      }
    }
  }, [templates, hasUserSelected, initialValue, onSelectedFormTemplate]);

  const handleChange = (value: string) => {
    setHasUserSelected(true);
    const selectedTemplate =
      templates.find((template) => template.id === value) || null;
    helper.setTouched(true, false); // no validate until setValue()
    helper.setValue(selectedTemplate?.id ?? "", true);
    onSelectedFormTemplate?.(selectedTemplate);
  };

  return (
    <SelectControl
      name={name}
      label={label}
      isRequired={isRequired}
      selectProps={{
        placeholder,
        onChange: (event) => {
          handleChange(event.target.value);
        },
        isDisabled: isDisabled,
      }}
    >
      {templates.map((template) => (
        <option key={template.id} value={template.id}>
          {template.name}
        </option>
      ))}
    </SelectControl>
  );
};
