import { Box, Button, HStack, Text } from "@chakra-ui/react";
import { EmptyState } from "src/components/EmptyState";
import { useFormImportTemplateDownload } from "src/components/FormImport/useFormImportTemplateDownload";
import { WithRequiredHasuraRoles } from "src/components/Permissions/WithRequiredHasuraRoles";
import { WithUserPermissions } from "src/components/Permissions/WithUserPermissions";
import { Glossary } from "src/components/Text/Glossary";
import { ReactComponent as ClipboardSvg } from "src/images/clipboard.svg";
import { HasuraRole } from "src/types/hasuraRole";
import { CreateFormsButton } from "./CreateFormsButton";

interface NoFormsProps {
  enrollmentPeriodId: uuid;
  formTemplateId: uuid;
  formTemplateName: string;
}

export function NoForms(props: NoFormsProps) {
  const { enrollmentPeriodId, formTemplateId, formTemplateName } = props;

  const { startDownload } = useFormImportTemplateDownload({
    formTemplateId,
  });

  return (
    <Box display="grid" minHeight="50vh" placeContent="center">
      <EmptyState
        description={
          <>
            <WithUserPermissions permissions={["form:create"]}>
              <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
                <Text textColor="gray.500">
                  Create a form for an individual, or multiple forms by
                  downloading and filling the template. Or wait for{" "}
                  <Glossary>students</Glossary> to start a form!
                </Text>
              </WithRequiredHasuraRoles>
              <WithRequiredHasuraRoles roles={[HasuraRole.ORG_ADMIN]}>
                <Text textColor="gray.500">
                  Create a form or wait for <Glossary>students</Glossary> to
                  start one!
                </Text>
              </WithRequiredHasuraRoles>
            </WithUserPermissions>
          </>
        }
        heading="No form responses yet"
        Svg={ClipboardSvg}
      >
        <HStack>
          <WithRequiredHasuraRoles roles={[HasuraRole.ADMIN]}>
            <WithUserPermissions permissions={["form_template:read"]}>
              <Button
                colorScheme="gray"
                onClick={startDownload}
                variant="outline"
              >
                Download template
              </Button>
            </WithUserPermissions>
          </WithRequiredHasuraRoles>
          <WithUserPermissions permissions={["form:create"]}>
            <CreateFormsButton
              enrollmentPeriodId={enrollmentPeriodId}
              formTemplateId={formTemplateId}
              formTemplateName={formTemplateName}
            />
          </WithUserPermissions>
        </HStack>
      </EmptyState>
    </Box>
  );
}
