import { AllOrgConfigs, DisabledConfig } from "../types";
import * as OrgConfig from "@avela/organization-config-sdk";

type InitialValue<T extends OrgConfig.Type> =
  | (OrgConfig.Config<T> & { disabled?: boolean })
  | OrgConfig.DisabledConfig;
export function getInitialValue<T extends OrgConfig.Type>(
  type: T,
  configs: AllOrgConfigs | undefined,
  empty?: OrgConfig.Config<T>
): InitialValue<T> {
  if (!configs?.[type] || configs[type].disabled) {
    // if configs is undefined or disabled, use default config
    return createDisabledInitialConfig(type, empty);
  }

  return configs[type];
}
export function createDisabledInitialConfig<T extends OrgConfig.Type>(
  type: T,
  empty?: OrgConfig.Config<T>
): InitialValue<T> {
  return {
    ...DisabledConfig,
    ...empty,
    ...OrgConfig.Constants.DefaultConfig[type],
  } as InitialValue<T>;
}
